import React, { useState, useEffect, useMemo } from "react";
import { StyleSheet, View } from "react-native";
import FormInput from "components/Partials/FormInput";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TableSortLabel
} from "@mui/material";
import { COLORS } from "constants/Colors";
import { PrimaryText } from "components/Common/PrimaryText";
import { useDashboard } from "context/DashboardContext";
import { nonprofitFilterOptions, nonprofitStripeFilterOptions, useOrganization } from "context/OrganizationContext";
import { OrgCard } from "components/Cards/OrgCard";
import { useNavigate } from "react-router";
import { Links } from "constants/Links";
import { searchOrg } from "services/organizations-service";
import { CustomInput } from "components/Inputs/CustomInput";
import MuiTablePagination from "components/Common/MuiTablePagination";
import { CustomPicker } from "components/Inputs/CustomPicker";

export const Nonprofits = () => {
	const navigate = useNavigate();
	const {
		orgs = [],
		setGetOrgs,
		setSelectedOrg,
		page,
		setPage,
		rowsPerPage,
		setRowsPerPage,
		orgsCount,
		sorting,
		setSorting,
		selectedView,
		setSelectedView,
		search,
		setSearch,
		nonProfits,
		selectedStripeFilter, setSelectedStripeFilter
	} = useOrganization();

	useEffect(() => {
		setGetOrgs(true);
	}, []);

	function onOrgSelected(org) {
		setSelectedOrg(org);
		navigate(Links.NonprofitDetail);
	}

	const currentTableData = () => {
		const orgsArray = nonProfits.map((doc) => ({
			...doc,
			employees: doc?.members?.length,
			verified: doc?.verified ? true : false,
			city: doc?.address?.city.toLowerCase?.(),
			totalDonations: doc?.totalDonations ? doc?.totalDonations : 0
		}));

		return orgsArray.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
	};

	const head = [
		{ id: "name", label: "Name" },
		{ id: "", label: "Employees" },
		{ id: "", label: "Stripe"},
		{ id: "", label: "501c3" },
		{ id: "", label: "Address" },
		{ id: "totalDonations", label: "Raised" },
		{ id: "createdAt", label: "Date Created" }
	];

	const handleSort = (property) => {
		if (!property) return;
		const isAsc = sorting.orderBy === property && sorting.orderIn === "asc";
		setPage(0);
		setSorting({
			orderIn: isAsc ? "desc" : "asc",
			orderBy: property
		});
	};

	return (
		<View style={styles.tableContainer}>
			{/* <div className="main-content manageAdmin"> */}
			<PrimaryText
				fontSize={24}
				fontWeight={700}
				style={styles.header}
			>
				Nonprofits
			</PrimaryText>

			<View
				style={{
					zIndex: 999,
					flexDirection: "row",
					width: "100%",
					alignItems: "center",
					justifyContent: "space-between",
					marginBottom: 12,
				}}
			>
				<View
					style={{ flexDirection: "row", alignItems: "center", flexGrow: 1, marginVertical: 10 }}
				>
					<CustomInput
						placeholder={"Search"}
						style={{ flexGrow: 1, maxWidth: 400 }}
						onChangeText={setSearch}
						value={search}
					/>
				</View>

				<View
					style={{
						flexDirection: "row",
						alignItems: "center",
					}}
				>
					<PrimaryText
						fontSize={14}
						fontWeight={800}
						style={{ color: COLORS.primary.darkGray, marginHorizontal: 4 }}
					>
						Stripe
					</PrimaryText>

					<CustomPicker
						value={selectedStripeFilter}
						onChange={(value) => setSelectedStripeFilter(value)}
						options={nonprofitStripeFilterOptions}
						style={{ flexGrow: 1 }}
					/>
					<PrimaryText
						fontSize={14}
						fontWeight={800}
						style={{ color: COLORS.primary.darkGray, marginHorizontal: 4, marginLeft: 12 }}
					>
						501c3 Status
					</PrimaryText>

					<CustomPicker
						value={selectedView}
						onChange={(value) => setSelectedView(value)}
						options={nonprofitFilterOptions}
						style={{ flexGrow: 1 }}
					/>
				</View>
			</View>


			<div className="flex-between-center">
				<div className="d-flex align-items-center w-60">
					<div className="flex-1"></div>
				</div>

				{/* <CSVLink
          {...csvReport}
          className="app-button btn-primary wh-unset p-10"
          style={{ backgroundColor: "#1DA1F2" }}
        >
          Export CSV
        </CSVLink> */}
			</div>

			<Table
				className="table"
				style={{ borderTopLeftRadius: 12, overflow: "hidden" }}
			>
				<TableHead>
					<TableRow>
						<TableCell></TableCell>
						{head.map((item, index) => (
							<TableCell key={index}>
								<TableSortLabel
									onClick={() => handleSort(item.id)}
									active={sorting.orderBy === item.id}
									direction={
										sorting.orderBy === item.id ? sorting.orderIn : "asc"
									}
									hideSortIcon={item.label === "Employees" || item.label === "501c3" || item.label === "Address"}
									sx={{
										"&.MuiTableSortLabel-root": {
											color: "#fff"
										},
										"& .MuiTableSortLabel-icon": {
											color: "#fff !important"
										}
									}}
								>
									{item.label}
								</TableSortLabel>
							</TableCell>
						))}
					</TableRow>
				</TableHead>

				<TableBody>
					{currentTableData() &&
						currentTableData().map((item, i) => {
							return (
								<OrgCard
									org={item}
									key={i}
									onPress={() => onOrgSelected(item)}
								/>
							);
						})}
				</TableBody>
			</Table>
			<MuiTablePagination
				count={orgsCount}
				rowsPerPage={rowsPerPage}
				page={page}
				setCurrentPage={(e) => setPage(e)}
				onRowsPerPageChange={(e) => {
					setRowsPerPage(e.target.value);
					setPage(0);
				}}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	tableContainer: {
		width: "100%",
		borderRadius: 12,
		borderTopLeftRadius: 0,
		paddingVertical: 12
	},
	header: {
		color: COLORS.primary.darkBlue
	},
	subtitle: {
		color: COLORS.primary.darkBlue,
		marginHorizontal: 24
	}
});
