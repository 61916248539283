import * as React from "react";
import Button from "@mui/material/Button";

export default function MuiButton({ title, sx, onPress, ...props }) {
  return (
    <Button
      onClick={onPress}
      sx={{
        borderRadius: "20px",
        textTransform: "none",
        fontWeight: 900,
        fontSize: 16,
        fontFamily: "Montserrat",
        borderWidth: 1,
        paddingY: "3px",
        paddingX: "20px",
        boxShadow: 0,
        ...sx,
      }}
      {...props}
    >
      {title}
    </Button>
  );
}
