import React from "react";
import { PrimaryText } from "components/Common/PrimaryText";
import { TableCell, TableRow } from "@mui/material";
import { COLORS } from "constants/Colors";
import { getUsersName } from "utils/helpers";
import { useRayze } from "context/RayzeContext";

export const CRMLogCard = ({ item }) => {
  const { createdAt, log, user } = item;

  const { team } = useRayze();

  const userName = getUsersName(team.find(x => x.id===user))

  return (
    <TableRow hover>
      <TableCell>
        <PrimaryText
          fontSize={12}
          fontWeight={500}
          style={{ color: COLORS.primary.lightBlue }}
        >
          {createdAt.toDate().toDateString()}
        </PrimaryText>
      </TableCell>
      <TableCell style={{ textAlign: "left" }}>
        <PrimaryText fontSize={12} fontWeight={400}>
          {log}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={12} fontWeight={400}>
          {userName}
        </PrimaryText>
      </TableCell>
    </TableRow>
  );
};
