import React, { useState } from "react"
import { PrimaryText } from "components/Common/PrimaryText"
import { COLORS } from "constants/Colors"
import { StyleSheet, View } from "react-native"
import { EventsCard } from "components/Cards/EventsCard"
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { useEvents } from "context/EventContext"

export const EventsTab = () => {
  const { upcomingEvents, pastEvents } = useEvents()

  return (
    <View style={styles.tableContainer}>

      <Table className="table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Going</TableCell>
            <TableCell>Interested</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
        {upcomingEvents &&
          upcomingEvents.map((el, i) => {
            return <EventsCard item={el} key={i} />
          }
        )}
        </TableBody>
      </Table>

    </View>
  )
}

const styles = StyleSheet.create({
  tableContainer: {
    minHeight: 300,
    backgroundColor: COLORS.primary.offWhite,
  },
})
