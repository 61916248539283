import React, { useEffect, useState } from "react";
import { COLORS } from "constants/Colors";
import { StyleSheet, View, ScrollView, TouchableOpacity } from "react-native";
import { EventsCard } from "components/Cards/EventsCard";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEvents } from "context/EventContext";
import { PrimaryText } from "components/Common/PrimaryText";
import * as FaIcons from "react-icons/fa";
import { ConfirmModal } from "components/Modals/Confirmation";
import { useAuth } from "context/AuthContext";
import { useOrganization } from "context/OrganizationContext";
import { ActivityIndicator } from "react-native-web";
import { createEvent, deleteEvent } from "services/events-service";

const geofire = require("geofire-common");

export const Events = () => {
  const { selectedOrg, userType, currentMember } = useOrganization();
  const { upcomingEvents, pastEvents } = useEvents();
  const { currentUser } = useAuth();

  let nowPlusHour = new Date();
  nowPlusHour.setHours(nowPlusHour.getHours() + 1);

  const defaultDetails = {
    about: "",
    active: true,

    address: {
      line1: "",
      city: "",
      state: "",
      zipCode: "",
    },
    createdAt: new Date(),
    causes: [],
    checkedIn: [],
    creator: currentUser.id,
    date: new Date(),
    endTime: nowPlusHour,
    latLng: "",
    meetingInfo: "",
    mode: "",
    name: "",
    orgId: selectedOrg?.id,
    orgName: selectedOrg?.name,
    orgType: selectedOrg?.type,
    startTime: new Date(),
    type: "event",
  };

  const [showNewEventModal, setShowNewEventModal] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [details, setDetails] = useState(defaultDetails);
  const [selectedCauses, setSelectedCauses] = useState([]);
  const [newEventCoverPhotos, setNewEventCoverPhotos] = useState([]);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    details.mode &&
    (details.mode === "In-Person"
      ? details.address.line1 &&
        details.address.city &&
        details.address.state &&
        details.address.zipCode &&
        details.latLng
      : details.meetingInfo) &&
    details.name !== "" &&
    details.about !== "" &&
    details.endTime > details.startTime &&
    selectedCauses?.length > 0 &&
    newEventCoverPhotos.length > 0
      ? setDisabled(false)
      : setDisabled(true);
  }, [details, newEventCoverPhotos, selectedCauses]);

  useEffect(() => {
    let date = details.date;
    let eventDate = date.getDate();
    let eventMonth = date.getMonth();

    details.startTime.setDate(eventDate);
    details.endTime.setDate(eventDate);
    details.startTime.setMonth(eventMonth);
    details.endTime.setMonth(eventMonth);
  }, [details]);

  const saveEvent = () => {
    setLoading(true);

    details.causes = selectedCauses;
    details.lowercaseName = details.name
      .toLowerCase()
      .replace(/[^a-zA-Z ]/g, "");
    details.skills = [];
    const { lat, lng } = details.latLng;
    const hash = geofire.geohashForLocation([lat, lng]);
    details.hash = hash;
    details.orgType = selectedOrg.type;
    details.orgName = selectedOrg.name;
    details.address.city = details.address.city.toLowerCase();
    details.address.state = details.address.state.toLowerCase();

    if (selectedOrg.type === "nonprofit") details.trendingScore = 0;

    createEvent(details, newEventCoverPhotos, onSuccess);
  };

  function onSuccess(event) {
    setLoading(false);
    setShowNewEventModal(false);
    setDetails(defaultDetails);
  }

  function onDeleteEvent() {
    setLoading(true);
    deleteEvent(showConfirmModal, onSuccessDelete);
  }

  function onSuccessDelete() {
    setShowConfirmModal(null);
    setLoading(false);
  }

  return (
    <View style={styles.tableContainer}>
      {currentMember?.postNewOpportunities && (
        <TouchableOpacity
          style={styles.button}
          onPress={() => setShowNewEventModal(true)}
        >
          <PrimaryText
            fontSize={14}
            fontWeight={300}
            style={{ color: COLORS.primary.white, marginRight: 12 }}
          >
            New Event
          </PrimaryText>
          <FaIcons.FaPlus size={16} color={COLORS.primary.white} />
        </TouchableOpacity>
      )}

      <Table
        className="table"
        style={{ borderTopLeftRadius: 12, overflow: "hidden" }}
      >
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Going</TableCell>
            <TableCell>Interested</TableCell>
            {userType === "Leader" && <TableCell></TableCell>}
          </TableRow>
        </TableHead>

        <TableBody>
          {upcomingEvents &&
            upcomingEvents.map((el, i) => {
              return (
                <EventsCard
                  item={el}
                  key={i}
                  deleteEvent={
                    userType === "Leader" ? () => setShowConfirmModal(el) : null
                  }
                />
              );
            })}
          {pastEvents &&
            pastEvents.map((el, i) => {
              return (
                <EventsCard
                  item={el}
                  key={i}
                  deleteEvent={
                    userType === "Leader" ? () => setShowConfirmModal(el) : null
                  }
                />
              );
            })}
        </TableBody>
      </Table>

      {!!showConfirmModal && (
        <ConfirmModal
          title={"Delete Event"}
          message={"Are you sure?"}
          onConfirm={onDeleteEvent}
          onCancel={() => setShowConfirmModal(false)}
        />
      )}

      {loading && (
        <ActivityIndicator
          animating={loading}
          size={"large"}
          style={styles.activity}
          color={COLORS.primary.lightBlue}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  activity: {
    width: "100%",
    height: "100%",
    backgroundColor: COLORS.primary.white,
    position: "absolute",
    top: 0,
    bottom: 0,
    zIndex: 999,
  },
  tableContainer: {
    width: "100%",
    // backgroundColor: COLORS.primary.offWhite,
    borderRadius: 12,
    borderTopLeftRadius: 0,
    paddingVertical: 12,
  },
  button: {
    flexDirection: "row",
    backgroundColor: COLORS.primary.lightBlue,
    width: 200,
    justifyContent: "center",
    alignItems: "center",
    padding: 8,
    margin: 12,
    borderRadius: 6,
  },
  headerContainer: {
    flexDirection: "row",
  },
  infoContainer: {
    backgroundColor: COLORS.primary.white,
    marginHorizontal: 12,
    borderRadius: 12,
    paddingHorizontal: 20,
    paddingVertical: 12,
    alignItems: "center",
    justifyContent: "center",
  },
  info: {
    alignItems: "center",
    justifyContent: "center",
    marginHorizontal: 24,
  },
  title: {
    marginBottom: 12,
  },
});
