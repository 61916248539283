import React, { useEffect } from "react"
import { StyleSheet, View, Image } from "react-native"
// import LinearGradient from "react-native-linear-gradient"
import { COLORS } from "constants/Colors"
import { useDisplay } from "context/DisplayContext"
import { getProfilePicture } from "services/user-service"

export const ProfilePic = (props) => {
  const {darkMode} = useDisplay()
  const [imageUri, setImageUri] = React.useState(null)

  useEffect(() => {
    setImageUri(props?.uri)
    props.userId && getProfilePicture(props.userId, setImageUri)
  }, [props.uri, props.userId])

  const borderSize = props.size-3
  const picSize = props.size-5

  const style = styles(darkMode)

  return (
    //   <LinearGradient 
    //   style={[style.container,{
    //       height: props.size,
    //       width: props.size,
    //       borderRadius: props.size / 2,
    //       overflow: "hidden",
    //     }]}
    //   useAngle={true}
    //   angle={140}
    //   colors={['#FBDF4B', '#48A0EB', '#DC3956', '#FBDF4B']}
    // >
      <View style={[style.innerContainer, { width: borderSize, height: borderSize, borderRadius: borderSize/2 }]}>
      <Image
        style={{ width: picSize, height: picSize, borderRadius: picSize/2 }}
        resizeMode={"contain"}
        source={
          imageUri
            ? { uri: imageUri }
            : require("assets/misc/profilePic.png")
        }
      />
      </View>
      // </LinearGradient>
  )
}

const styles = (darkMode) => StyleSheet.create({
  container: {
    aspectRatio: 1,
    alignItems: 'center', justifyContent: 'center',
  },
  innerContainer: {
    alignItems: 'center', justifyContent: 'center',
    backgroundColor: darkMode ? COLORS.primary.darkBlue : COLORS.primary.white
  }
})
