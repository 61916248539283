import React, { createContext, useContext, useEffect, useState } from "react";

import { useAuth } from "./AuthContext";
import {
  getOrgInvitedMembers,
  getOrgMembers,
  getUserOrgs,
} from "services/organizations-service";
import { functions } from "utils/firebase";

const RayzeContext = createContext();

export const RayzeProvider = (props) => {
  const { currentUser, onboarding } = useAuth();

  const [selectedOrg, setSelectedOrg] = useState(null);
  const [members, setMembers] = useState([]);
  const [invitedMembers, setInvitedMembers] = useState([]);
  const [owner, setOwner] = useState(false);
  const [userType, setUserType] = useState("");
  const [currentMember, setCurrentMember] = useState(null);
  const [team, setTeam] = useState([]);
  const [getTeam, setGetTeam] = useState(false);

  let orgSubscriber;
  useEffect(() => {
    if (currentUser && !onboarding) {
      orgSubscriber = getUserOrgs(setSelectedOrg);
    }

    return () => {
      orgSubscriber && orgSubscriber();
    };
  }, [currentUser, onboarding]);

  useEffect(() => {
    if (!selectedOrg) return;

    const memberSub = getOrgMembers(
      currentUser,
      selectedOrg,
      setMembers,
      setUserType,
      setCurrentMember
    );
    const inviteSub = getOrgInvitedMembers(selectedOrg, setInvitedMembers);
    setOwner(selectedOrg?.owner === currentUser.id);

    return () => {
      memberSub();
      inviteSub();
    };
  }, [selectedOrg]);

  useEffect(() => {
    if (!members || members?.length === 0 || !selectedOrg) return;
    setGetTeam(true);
    return () => {
      setGetTeam(false);
    };
  }, [members, selectedOrg]);

  useEffect(() => {
    if (!getTeam) return;
    const membersMutate = members.map((x) => {
      return { uid: x.id };
    });
    const stringifiedMembers = JSON.stringify(membersMutate);
    functions
      .httpsCallable("getAuthUsers")(stringifiedMembers)
      .then((res) => {
        let temp = [];
        members.forEach((member) => {
          const find = res?.data.find((x) => x.uid === member.id);
          if (find) member.email = find.email;
          temp.push(member);
        });
        setTeam(temp);
      })
      .catch((e) => {
        console.log("error getting auth team", e);
      });
  }, [getTeam, members]);

  return (
    <RayzeContext.Provider
      value={{
        selectedOrg,
        setSelectedOrg,
        members,
        setMembers,
        team,
        setTeam,
        invitedMembers,
        currentMember,

        owner,
        userType,
      }}
    >
      {props.children}
    </RayzeContext.Provider>
  );
};

export const useRayze = () => useContext(RayzeContext);
