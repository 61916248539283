import { firestore } from "utils/firebase";

export const getPBCDonations = async (setHistory) => {
  firestore
    .collection("donations")
    .where("cot", "==", true)
    // .orderBy("date", "desc")
    .get()
    .then((querySnapshot) => {
      let history = [];
      querySnapshot.forEach((doc) => {
        history.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      setHistory(history);
    })
    .catch((e) => {
      console.log("getRecentDonations error", e);
    });
};

export const getPBCCorporateDonations = async (setHistory) => {
  firestore
    .collection("donations_corporate_match")
    .where("cot", "==", true)
    // .orderBy("date", "desc")
    .get()
    .then((querySnapshot) => {
      let history = [];
      querySnapshot.forEach((doc) => {
        history.push({
          ...doc.data(),
          id: doc.id + "-match",
          match: true,
        });
      });
      setHistory(history);
    })
    .catch((e) => {
      console.log("getRecentDonations error", e);
    });
};

export const updateDonationAsContacted = async (
  donationId,
  contacted,
  onSuccess
) => {
  await firestore
    .collection("donations")
    .doc(donationId)
    .update({
      contacted,
    })
    .catch((error) => {
      console.error("Error updating document: ", error);
    });

  onSuccess();
  console.log("Document successfully updated!");
};

export const updateDonationAsCheckSent = async (
  donationId,
  checkSent,
  onSuccess
) => {
  await firestore
    .collection("donations")
    .doc(donationId)
    .update({
      checkSent,
    })
    .catch((error) => {
      console.error("Error updating document: ", error);
    });

  onSuccess();
  console.log("Document successfully updated!");
};

export const updateDonationAsCheckCashed = async (
  donationId,
  checkCashed,
  onSuccess
) => {
  console.log('updateDonationAsCheckCashed', donationId, checkCashed)
  await firestore
    .collection("donations")
    .doc(donationId)
    .update({
      checkCashed,
    })
    .catch((error) => {
      console.error("Error updating document: ", error);
    });

  onSuccess();
  console.log("Document successfully updated!");
};