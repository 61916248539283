import { firestore, storage } from "utils/firebase";
import moment from "moment";
import { DateRange } from 'react-date-range';

export const convertToDateString = (value) => {
  if (!value) return "";
  if (value.seconds !== undefined) {
    return new Date(
      value.seconds * 1000 + value.nanoseconds / 1000000
    ).getTime();
  } else return value;
};

export const getAgeFromBirthday = (timestamp) =>
  parseInt((new Date().getTime() - timestamp) / (1000 * 60 * 60 * 24 * 365));

export const getProfileImage = async (userId) =>
  await storage.ref(`/profilePictures/${userId}.jpg`).getDownloadURL();

export const getImageFromStorageByRef = async (ref) =>
  await storage.ref(ref).getDownloadURL();

//to be used in the future
//  export const uploadImage = async (e,path) => {
//     const file = e.target.files[0];
//     const storageRef = storage().ref(path);
//     const fileRef = storageRef.child(file.name);
//     await fileRef.put(file);
//     return await fileRef.getDownloadURL();
//   };

export const uploadImageWithUid = async (uuid, image, setState) => {
  await storage.ref(uuid).put(image)
  const url = await storage.ref(uuid).getDownloadURL()
  return setState(url)
}


export const getSubCollection = async (
  mainCollection,
  mainDocId,
  subCollection
) =>
  await firestore
    .collection(mainCollection)
    .doc(mainDocId)
    .collection(subCollection)
    .get();
var phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
export const formattedPhoneNumber = (phoneNumber) => phoneNumber?.replace(phoneRegex, "($1) $2-$3");

export const capitalize = (value) => value?.charAt(0).toUpperCase() + value?.slice(1);
export const formattedDateWithEmailSign = (date) => moment(new Date(date?.seconds * 1000)).format("MM/DD/YY @ hh:mm a")
export const formattedDate = (date) => {
  if (date?.seconds) {
    return (moment(new Date(date.seconds * 1000)).format("MM/DD/YYYY"))
  } else {
    return (moment(date?.toDate().toDateString()).format(
      "MM/DD/YYYY"
    ))
  }
}
export const formateFilterDate = (date) => {
  if (date?.seconds) {
    return (moment(new Date(date?.seconds * 1000)).format("YYYY-MM-DD"))
  } else {
    return (moment(date?.toDate().toDateString()).format(
      "YYYY-MM-DD"
    ))
  }
}
export const ageFromDOB = (date) => {
  const currentDate = new Date();
  return currentDate.getFullYear() - moment(date?.toDate().toDateString()).format('YYYY')


}

export const toTimestamp = (date) => {
  console.log(date);
  // # valid js Date and time object format (YYYY-MM-DDTHH:MM:SS)
  var dateTimeParts = date.split(' ');

  // # this assumes time format has NO SPACE between time and am/pm marks.
  if (dateTimeParts[1].indexOf(' ') == -1 && dateTimeParts[2] === undefined) {

    var theTime = dateTimeParts[1];

    // # strip out all except numbers and colon
    var ampm = theTime.replace(/[0-9:]/g, '');

    // # strip out all except letters (for AM/PM)
    var time = theTime.replace(/[[^a-zA-Z]/g, '');

    if (ampm == 'pm') {

      time = time.split(':');

      // # if time is 12:00, don't add 12
      if (time[0] == 12) {
        time = parseInt(time[0]) + ':' + time[1] + ':00';
      } else {
        time = parseInt(time[0]) + 12 + ':' + time[1] + ':00';
      }

    } else { // if AM

      time = time.split(':');

      // # if AM is less than 10 o'clock, add leading zero
      if (time[0] < 10) {
        time = '0' + time[0] + ':' + time[1] + ':00';
      } else {
        time = time[0] + ':' + time[1] + ':00';
      }
    }
  }
  // # create a new date object from only the date part
  var dateObj = new Date(dateTimeParts[0]);

  // # add leading zero to date of the month if less than 10
  var dayOfMonth = (dateObj.getDate() < 10 ? ("0" + dateObj.getDate()) : dateObj.getDate());

  // # parse each date object part and put all parts together
  var yearMoDay = dateObj.getFullYear() + '-' + (dateObj.getMonth() + 1) + '-' + dayOfMonth;

  // # finally combine re-formatted date and re-formatted time!
  var date = new Date(yearMoDay + 'T' + time);

  return date;
}




//filter for city,zip,state and distance(not yet done)
export const frontEndFilter = (data, setState, city, zipCode, state) => {
  try {
    const results = data.filter((cell) =>
      ` ${cell?.zipCode} ${cell?.city.toLowerCase()} ${cell?.state.toLowerCase()}`
        .includes(`${zipCode && zipCode}` || `${city && city}` || `${state && state}`)
    );
    return setState(results);
  } catch (error) {
    console.log('error while filtering', error.message)

  }
}


//filter for business and non profit city,zip,state and distance(not yet done)
export const frontEndFilterForBusNon = (data, setState, city, zipCode, state) => {
  console.log("value in state", state);
  try {
    const results = data.filter((cell) =>
      ` ${cell?.address.city.toLowerCase()} ${cell?.address.state.toLowerCase()} ${cell?.address.zipcode}`
        .includes(`${zipCode && zipCode}` || `${city && city}` || `${state && state}`)
    );
    return setState(results);
  } catch (error) {
    console.log('error while filtering', error.message)

  }
}

//filter by DateRange

export const filterBetweenDates = (data, setState, fromDate, toDate) => {
  try {
    const oldEvents = [...data];
    const filteredDates = (e) => {
      return (
        formateFilterDate(
          e.created ? e.created : e.date) >= fromDate &&
        formateFilterDate(e.created ? e.created : e.date) <= toDate
      );
    };
    const filteredAllData = oldEvents.filter(filteredDates);
    setState(filteredAllData);
  } catch (error) {
    console.log('error while filtering by dates', error.message);
  }
}
export const filterBetweenDatesCreatedAt = (data, setState, fromDate, toDate) => {
  try {
    const oldEvents = [...data];
    const filteredDates = (e) => {
      console.log(fromDate, toDate)
      return (
        formateFilterDate(
          e?.createdAt ? e?.createdAt : null) >= fromDate &&
        formateFilterDate(e?.createdAt ? e?.createdAt : e?.expiriationDate) <= toDate
      );
    };
    const filteredAllData = oldEvents.filter(filteredDates);
    setState(filteredAllData);
  } catch (error) {
    console.log('error while filtering by dates', error.message);
  }
}

export const generateRandomPassword = (count) => {
  const letter =
    "0123456789ABCDEFGHIJabcdefghijklmnopqrstuvwxyzKLMNOPQRSTUVWXYZ0123456789abcdefghiABCDEFGHIJKLMNOPQRST0123456789jklmnopqrstuvwxyz";
  let randomString = "";
  for (let i = 0; i < count; i++) {
    const randomStringNumber = Math.floor(
      1 + Math.random() * (letter.length - 1)
    );
    randomString += letter.substring(
      randomStringNumber,
      randomStringNumber + 1
    );
  }
  return randomString;
};