import React from "react"
import { PrimaryText } from "components/Common/PrimaryText"
import { TableCell, TableRow } from "@mui/material"
import { getEventDate } from "utils/events-helper"
import { useNavigate } from "react-router-dom"
import { Links } from "constants/Links"
import { useEvents } from "context/EventContext"
import { GiTrashCan } from "react-icons/gi"
import { COLORS } from "constants/Colors"
import { TouchableOpacity } from "react-native-web"

export const EventsCard = ({ item, deleteEvent }) => {
  const { name, interested, going } = item

  const navigate = useNavigate()
  const { setSelectedEvent } = useEvents()

  const onSelectEvent = () => {
    setSelectedEvent(item)
    navigate(Links.EventDetail)
  }

  return (
    <TableRow onClick={onSelectEvent} hover>
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={400}>
          {name}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={400}>
          {getEventDate(item, true)}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={400}>
          {going || 0}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={400}>
          {interested || 0}
        </PrimaryText>
      </TableCell>
      {deleteEvent && (
        <TableCell>
          <TouchableOpacity onPress={deleteEvent}>
            <GiTrashCan size={20} color={COLORS.primary.error} />
          </TouchableOpacity>
        </TableCell>
      )}
    </TableRow>
  )
}
