import React, { useEffect, useState } from "react"
import { PrimaryText } from "components/Common/PrimaryText"
import { ProfilePic } from "components/Pics/ProfilePic"
import { TableCell, TableRow } from "@mui/material"
import { getUsersName } from "utils/helpers"

export const EventAttendeeCard = (props) => {
  const { user } = props

  return (
    <TableRow hover onClick={() => console.log('user clicked', user?.id)}>
      <TableCell>
        <ProfilePic uri={user?.profilePic} size={36} />
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={400}>
          {getUsersName(user)}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={400}>
          @{user?.username}
        </PrimaryText>
      </TableCell>

    </TableRow>
  )
}
