import { firestore, storage } from "utils/firebase";

// export const cotAccount = "acct_1M49PfFjkJEvnTwL"; //dev
export const cotAccount = "acct_1MuOLqFjVM8m8DG7"; //prod

export const getCOTBearer = async () => {
	const query = firestore.collection("rayze").doc("cot");

	const doc = await query.get().catch((e) => {
		console.log("getCOTBearer error", e);
	});
	if (!doc.exists) return;
	const data = doc.data();
	const bearer = data?.bearer;
	return bearer;
};

export const searchCOT = (search, setResults, bearer) => {
	const url = `https://s.poweredbycharity.com/api/principal/search?search=${search}&result_count=10&page=1`;
	const options = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${bearer}`,
			Accept: "application/json"
		}
	};

	fetch(url, options)
		.then((res) => res.json())
		.then((data) => {
			// console.log("data", data);

			// console.log("here", data);
			const results = data?.hits?.map((hit) => {
				let ein = hit.ein;
				ein = String(ein);
				if (ein?.length === 8) {
					ein = "0" + ein;
				}
				if (ein?.length === 9) {
					ein = ein.substring(0, 2) + "-" + ein.substring(2, 9);
				} else {
					return;
				}
				return {
					id: ein,
					ein: ein,
					name: hit.name,
					cot: true,
					stripeID: cotAccount,
					stripeComplete: true,
					address: {
						city: hit.city,
						state: hit.state,
						zip: hit.zip,
						street: hit.street
					}
				};
			});
			setResults(results || null);
		});
};