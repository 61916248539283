export const Links = {
  Landing: "/",

  Dashboard: "/dashboard",
  Team: "/team",
  Users: "/users",
  UserDetail: "/userDetail",
  Causes: "/causes",
  Skills: "/skills",
  Nonprofits: "/nonprofits",
  NonprofitDetail: "/nonprofitDetail",
  Fundraising: "/fundraising",
	FundraisingDetail: "/fundraisingDetail",

  Donations: "/donations",
  Events: "/events",
  EventDetail: "/eventDetail",
  Opportunities: "/opportunities",

  CRM: "/crm",
  CRMDetail: "/crmDetail",

  PBC: "/pbc",
};
