import { COLORS } from "./Colors"

export const ROLES = [
    {
        value: 'Leader',
        label: 'Admin',
    },
    {
        value: 'Employee',
        label: 'User',
    }
]

export const gendersOptions = [
    {
      id: "male",
      name: "M",
    },
    {
      id: "female",
      name: "F",
    },
    {
        id: 'other',
        name: 'X'
    }
]

export const CRM_Status = [
    {
        value: 1,
        label: 'Not Yet Contacted',
        color: COLORS.primary.error
    },
    {
        value: 2,
        label: 'In Progress',
    },
    {
        value: 3,
        label: 'Org Created',
    },
    {
        value: 5,
        label: 'Verified',
    },
    {
        value: 4,
        label: 'Accepting Donations',
    }
]

export const CRM_ContactStatus = [
    {
        value: 1,
        label: 'Not Yet Contacted',
        color: COLORS.primary.error
    },
    {
        value: 2,
        label: 'Awaiting Response',
    },
    {
        value: 3,
        label: 'Cold',
    },
    {
        value: 4,
        label: 'Not Interested',
    }
]

export const CRM_LeadSources = [
    {
        value: 1,
        label: 'Personal Connection',
    },
    {
        value: 2,
        label: 'Reached Out To Us',
    },
    {
        value: 3,
        label: 'Facebook',
    },
    {
        value: 4,
        label: 'Instagram',
    },
    {
        value: 5,
        label: 'Other'
    }
]

export const CRM_NumOfEmployees = [
    {
        value: 1,
        label: '<10',
    },
    {
        value: 2,
        label: '11-25',
    },
    {
        value: 3,
        label: '25-50',
    },
    {
        value: 4,
        label: '51-100',
    },
    {
        value: 5,
        label: '100+',
    }
]

