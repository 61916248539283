import React from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import { Links } from "constants/Links"
import { View } from "react-native-web"

import { Dashboard } from "screens/Dashboard/Dashboard"
import { useDisplay } from "context/DisplayContext"
import { Team } from "screens/Team/Team"
import { Events } from "screens/Events/Events"
import { EventDetail } from "screens/Events/EventDetail"
import { Users } from "screens/Users/Users"
import { Causes } from "screens/Causes/Causes"
import { Skills } from "screens/Skills/Skills"
import { Nonprofits } from "screens/Nonprofits/Nonprofits"
import { NonprofitDetail } from "screens/Nonprofits/NonprofitDetail"
import { UserDetail } from "screens/Users/UserDetail"
import { CRM } from "screens/CRM/CRM"
import { CRMDetail } from "screens/CRM/CRMDetail"
import { PBC } from "screens/PBC/PBC"
import { Fundraising } from "screens/Fundraising/Fundraising"
import { FundraisingDetail } from "screens/Fundraising/FundraisingDetail"

export default function MainNavigator() {
  const { showSidebar, setShowSidebar } = useDisplay()

  return (
    <View
      style={{
        flex: 1,
        marginLeft: showSidebar ? 280 : 60,
        alignItems: "center",
        justifyContent: "center",
        paddingHorizontal: 24,
        minWidth: 1024,
        paddingTop: 4,
      }}
    >
      <Routes>
        <Route path={Links.Dashboard} element={<Dashboard />} />

        {/** redirects */}
        <Route
          path={"/*"}
          element={<Navigate replace to={Links.Dashboard} />}
        />

        <Route path={Links.Team} element={<Team />} />
        <Route path={Links.Users} element={<Users />} />
        <Route path={Links.UserDetail} element={<UserDetail />} />
        <Route path={Links.Causes} element={<Causes />} />
        <Route path={Links.Skills} element={<Skills />} />
        <Route path={Links.Nonprofits} element={<Nonprofits />} />
        <Route path={Links.NonprofitDetail} element={<NonprofitDetail />} />
        <Route
					path={Links.Fundraising}
					element={<Fundraising />}
				/>
				<Route
					path={Links.FundraisingDetail}
					element={<FundraisingDetail />}
				/>

        <Route path={Links.Events} element={<Events />} />
        <Route path={Links.EventDetail} element={<EventDetail />} />

        <Route path={Links.CRM} element={<CRM />} />
        <Route path={Links.CRMDetail} element={<CRMDetail />} />

        <Route path={Links.PBC} element={<PBC />} />
      </Routes>
    </View>
  )
}
