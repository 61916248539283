import React, { useState, createContext, useEffect, useContext } from "react"
import { logout } from "services/auth-service"
import { getProfilePicture, listenToUser } from "services/user-service"
import { auth } from "utils/firebase"

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [initializing, setInitializing] = useState(true)
  const [currentAuth, setCurrentAuth] = useState(null)
  const [currentUser, setCurrentUser] = useState(null)

  // Handle user state changes
  function onAuthStateChanged(auth) {
    setCurrentAuth(auth)
    if (initializing) setInitializing(false)
  }

  useEffect(() => {
    const subscriber = auth.onAuthStateChanged(onAuthStateChanged)
    return subscriber // unsubscribe on unmount
  }, [])

  useEffect(() => {
    if (!currentAuth) return

    const userSubscriber = listenToUser(currentAuth, setCurrentUser, onError)

    return () => {
      setCurrentUser(null)
      userSubscriber()
    }
  }, [currentAuth])

  function onError() {
    window.alert("no user found")
    logout()
  }

  if (initializing) return null

  return (
    <AuthContext.Provider
      value={{
        initializing,
        currentAuth,
        setCurrentAuth,
        currentUser,
        setCurrentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
