import React from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import { COLORS } from "constants/Colors";
import { PrimaryText } from "components/Common/PrimaryText";
import * as FiIcons from "react-icons/fi";
import { getRole, getUsersName } from "utils/helpers";
import { ROLES } from "constants/Data";
import { TableCell, TableRow } from "@mui/material";
import { ProfilePic } from "components/Pics/ProfilePic";

export const EditUserCard = ({
  item,
  onArchive,
  onEdit,
  isCurrentUser,
  currentUserRole,
}) => {
  let style = styles(false);

  return (
    <TableRow>
      <TableCell width={50}>
        <ProfilePic uri={item?.profilePic} size={50} />
      </TableCell>
      <TableCell style={{ textAlign: "left" }}>
        <PrimaryText
          fontSize={20}
          fontWeight={800}
          style={[
            style.detailLabel,
            { marginRight: 12, width: 300, textAlign: "left" },
          ]}
        >
          {item?.firstName ? getUsersName(item) : item?.email}
          {!item?.verified && " (Invite Sent)"}
        </PrimaryText>
      </TableCell>

      <TableCell>
        <PrimaryText fontSize={16} fontWeight={500}>
          {getRole(item)?.label}
        </PrimaryText>
      </TableCell>

      <TableCell>
        <PrimaryText fontSize={16} fontWeight={500} style={{color: COLORS.primary.lightBlue, textDecoration: 'underline'}}>
          {item?.email}
        </PrimaryText>
      </TableCell>
      {currentUserRole === "Leader" && (
        <>
          {!isCurrentUser ? (
            <>
              <TableCell width={70}>
                <TouchableOpacity
                  onPress={onEdit}
                  style={style.buttonContainer}
                >
                  <FiIcons.FiEdit size={20} color={COLORS.primary.gray} />
                </TouchableOpacity>
              </TableCell>
              <TableCell width={70}>
                <TouchableOpacity
                  onPress={onArchive}
                  style={style.buttonContainer}
                >
                  {item.invite ? (
                    <FiIcons.FiTrash2 size={20} color={COLORS.primary.error} />
                  ) : (
                    <FiIcons.FiArchive
                      size={20}
                      color={COLORS.primary.black}
                    />
                  )}
                </TouchableOpacity>
              </TableCell>
            </>
          ) : (
            <>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </>
          )}
        </>
      )}
    </TableRow>
  );
};

export const styles = (darkMode) =>
  StyleSheet.create({
    card: {
      paddingHorizontal: 12,
      paddingVertical: 8,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderColor: COLORS.primary.lightGray,
      borderBottomWidth: 1,
    },
    leftContainer: {
      flex: 1,
    },
    buttonContainer: {
      // width: 70,
      alignItems: "center",
    },
    detailLabel: {
      // marginHorizontal: 6,
      // width: 70,
      textAlign: "center",
    },
  });
