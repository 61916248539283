import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { MuiTheme } from "constants/MuiTheme";

const theme = createTheme(MuiTheme);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
    <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('rayze')
);
