import React, { useEffect, useState } from "react";
import { PrimaryText } from "components/Common/PrimaryText";
import { TableCell, TableRow } from "@mui/material";
import { OrgPic } from "components/Pics/OrgPic";
import { COLORS } from "constants/Colors";
import {
  dollarFormatter,
  getCityStateZip,
  getFullLocation,
  getUsersName,
} from "utils/helpers";
import { CRM_NumOfEmployees, CRM_Status } from "constants/Data";
import * as FiIcons from "react-icons/fi";
import { TouchableOpacity } from "react-native-web";

export const CRMCard = ({ item, onPress, onEdit, team }) => {
  const {
    profilePic = "",
    name,
    leadOwner: leadOwnerId,
    status: statusValue,
    estimatedRevenue,
    numOfEmployees: employees,
    address,
    members,
    verified = false,
  } = item;

  const leadOwner = leadOwnerId
    ? getUsersName(team.find((x) => x.id === leadOwnerId))
    : "";
  const status = CRM_Status.find((x) => x.value === statusValue);
  const numOfEmployees = CRM_NumOfEmployees.find(
    (x) => x.value === employees
  )?.label;

  return (
    <TableRow hover onClick={onPress}>
      <TableCell width={36}>
        <OrgPic uri={profilePic} size={36} />
      </TableCell>
      <TableCell>
        <PrimaryText
          fontSize={12}
          fontWeight={500}
          style={{ color: COLORS.primary.lightBlue }}
        >
          {name}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={12} fontWeight={400}>
          {leadOwner}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText
          fontSize={12}
          fontWeight={400}
          style={
            !verified && { color: status?.color || COLORS.primary.darkGray }
          }
        >
          {status?.label}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={12} fontWeight={400}>
          {estimatedRevenue ? dollarFormatter.format(estimatedRevenue) : 'N/A'}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={12} fontWeight={400}>
          {numOfEmployees}
        </PrimaryText>
      </TableCell>
      <TableCell width={22}>
        <TouchableOpacity onPress={onEdit} style={{ alignItems: "center" }}>
          <FiIcons.FiEdit size={22} color={COLORS.primary.gray} />
        </TouchableOpacity>
      </TableCell>
    </TableRow>
  );
};
