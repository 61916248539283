import { firestore } from "utils/firebase";

export const listenForRecurringDonationChanges = (
  org,
  setOurRecurringDonations
) => {
  return firestore
    .collection("donations_recurring")
    .where("orgId", "==", org.id)
    .where("active", "==", true)
    .orderBy("startDate", "desc")
    .onSnapshot(
      (querySnapshot) => {
        let donations = []

        querySnapshot.forEach((doc) => {
          donations.push({
            id: doc.id,
            ...doc.data(),
          })
        })

        setOurRecurringDonations(donations)
      },
      (e) => {
        console.log("listenForRecurringDonationChanges err", e)
      }
    )
}

export const getDonationHistoryByOrgId = async (
  currentUser,
  selectedDonation,
  orgId,
  setHistory,
  setLoading
) => {
  firestore()
    .collection("donations")
    .where("orgId", "==", orgId)
    .where("userId", "==", currentUser.id)
    .orderBy("date", "desc")
    .get()
    .then((querySnapshot) => {
      let history = []
      querySnapshot.forEach((doc) => {
        history.push({
          ...doc.data(),
          id: doc.id,
          coverPhoto: selectedDonation?.coverPhoto,
          organization: selectedDonation?.organization,
        })
      })
      setHistory(history)
      setLoading(false)
    })
    .catch((e) => {
      setLoading(false)
      console.log("getDonationHistoryByOrgId error", e)
    })
}

export const getRecentDonations = async (
  selectedOrg,
  setHistory
) => {
  firestore
    .collection("donations")
    .orderBy("date", "desc")
    .limit(10)
    .get()
    .then((querySnapshot) => {
      let history = []
      querySnapshot.forEach((doc) => {
        history.push({
          ...doc.data(),
          id: doc.id,
        })
      })
      setHistory(history)
    })
    .catch((e) => {
      console.log("getRecentDonations error", e)
    })
}
