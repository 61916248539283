import React, { createContext, useContext, useState, useEffect } from "react";
import { listenForRayzeData } from "services/rayze-service";
import { useAuth } from "./AuthContext";

const DashboardContext = createContext();

export const useDashboard = () => useContext(DashboardContext);

export const DashboardProvider = ({ children }) => {

  const [rayzeStats, setRayzeStats] = useState([]);

  const { currentUser } = useAuth();
  useEffect(() => {
    if (!currentUser) return

    listenForRayzeData(setRayzeStats)
  }, [currentUser]);



  const value = {
    rayzeStats
  };

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
};
