import React, { createContext, useState, useEffect, useContext } from "react"
import { fetchMoreUsers } from "services/user-service"
import { useCauses } from "./CausesContext"
import { getOurCauses } from "services/causes-service"

export const UserContext = createContext()

export const UserProvider = ({ children }) => {
  const { allCauses } = useCauses()
  const [lastDoc, setLastDoc] = useState(null)
  const [users, setUsers] = useState([])
  const [getUsers, setGetUsers] = useState(false)
  const [page, setPage] = useState(1)

  const [selectedUser, setSelectedUser] = useState(null)
  const [causes, setCauses] = useState(null)
  const [skills, setSkills] = useState(null)

  useEffect(() => {
    if (getUsers) {
      fetchMoreUsers(lastDoc, setLastDoc, users, setUsers)
    }
  }, [getUsers])

  function nextPage() {
    fetchMoreUsers(lastDoc, setLastDoc, users, setUsers)
    setPage(page + 1)
  }

  //set selected user causes
  useEffect(() => {
    if (!selectedUser || !allCauses) return
    getOurCauses(selectedUser, allCauses, setCauses)
  }, [selectedUser, allCauses])

  return (
    <UserContext.Provider
      value={{
        nextPage,
        users,
        setUsers,
        setGetUsers,
        selectedUser,
        setSelectedUser,
        causes,
        skills,
        page, setPage
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUsers = () => useContext(UserContext)
