import React, { useState, createContext, useEffect, useContext } from "react"
import {
  getRecentDonations,
  listenForRecurringDonationChanges,
} from "services/donations-service"
import { useRayze } from "./RayzeContext"

export const DonationsContext = createContext()

export const DonationsProvider = ({ children }) => {
  const { selectedOrg } = useRayze()

  const [recentDonations, setRecentDonations] = useState([])
  const [getDonations, setGetDonations] = useState(false)
  const [ourDonations, setOurDonations] = useState([])
  const [ourRecurringDonations, setOurRecurringDonations] = useState([])
  const [selectedDonation, setSelectedDonation] = useState(null)

  useEffect(() => {
    if (!selectedOrg) return
    getRecentDonations(selectedOrg, setRecentDonations)

    const sub = listenForRecurringDonationChanges(
      selectedOrg,
      setOurRecurringDonations
    )

    return () => {
      sub()
    }
  }, [selectedOrg])

  return (
    <DonationsContext.Provider
      value={{
        recentDonations,
        setGetDonations,
        ourDonations,
        ourRecurringDonations,
        selectedDonation,
        setSelectedDonation,
      }}
    >
      {children}
    </DonationsContext.Provider>
  )
}

export const useDonations = () => useContext(DonationsContext)
