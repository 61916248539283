import React, { useState, createContext, useEffect, useContext } from "react"
import { listenForDonationsToFundraiser, listenForFundraiserChanges } from "services/fundraisers-service"
import { getUsersWithZeroDollarFundraisers } from "services/fundraisers-service"
import { useOrganization } from "./OrganizationContext"

export const FundraiserContext = createContext()

export const FundraiserProvider = ({ children }) => {

  const { cotBearer } = useOrganization();

  const [getFundraisers, setGetFundraisers] = useState(false)
  const [ourFundraisers, setOurFundraisers] = useState([])
  const [zeroDollarFundraisers, setZeroDollarFundraisers] = useState([])
  const [allFundraisers, setAllFundraisers] = useState([])
  const [selectedFundraiser, setSelectedFundraiser] = useState(null)
  const [fundraiserDonations, setFundraiserDonations] = useState([])

  useEffect(() => {
    if (!getFundraisers) return

    const sub = listenForFundraiserChanges(
      setOurFundraisers, cotBearer
    )

    const zeroSub = getUsersWithZeroDollarFundraisers(setZeroDollarFundraisers, cotBearer);

    return () => {
      sub()
      zeroSub()
    }
  }, [getFundraisers])

  useEffect(() => {
    if (!selectedFundraiser) return;
    const findIndex = ourFundraisers.findIndex(f => f.id === selectedFundraiser.id)
    if (findIndex !== -1) {
      setSelectedFundraiser(ourFundraisers[findIndex])
    }
  }, [ourFundraisers])

  useEffect(() => {

    ourFundraisers.forEach(fundraiser => {
      const isActive = zeroDollarFundraisers.find(f => f.userId === fundraiser.userId && f.orgId === fundraiser.orgId)
      if (isActive) {
        isActive.delete = true
      }
      fundraiser.active = !!isActive
    });

    let concat = ourFundraisers.concat(zeroDollarFundraisers)
    //remove duplicates
    let unique = concat.filter(x => !x.delete)
    const sortedByActive = unique.sort((a, b) => a.active === b.active ? 0 : a.active ? -1 : 1)


    setAllFundraisers(sortedByActive)

  }, [ourFundraisers, zeroDollarFundraisers])

  useEffect(() => {
    if (!selectedFundraiser) return;

    const sub = listenForDonationsToFundraiser(
      selectedFundraiser.orgId,
      selectedFundraiser.userId,
      setFundraiserDonations
    )

    return () => {
      sub();
      setFundraiserDonations([])
    }

  }, [selectedFundraiser])

  return (
    <FundraiserContext.Provider
      value={{
        setGetFundraisers,
        ourFundraisers,
        selectedFundraiser,
        setSelectedFundraiser,
        fundraiserDonations,
        zeroDollarFundraisers,
        allFundraisers
      }}
    >
      {children}
    </FundraiserContext.Provider>
  )
}

export const useFundraisers = () => useContext(FundraiserContext)
