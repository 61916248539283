import React, { useEffect, useState } from "react"
import {
  StyleSheet,
  View,
  Image,
  Text,
  Dimensions,
  ActivityIndicator,
  TouchableHighlight,
  TouchableOpacity,
} from "react-native"
import { COLORS } from "constants/Colors"
import { CustomInput } from "components/Inputs/CustomInput"
import { login, sendPasswordReset } from "services/auth-service"
import { PrimaryText } from "components/Common/PrimaryText"

const { height } = Dimensions.get("window")

export function Landing() {
  const [email, setEmail] = useState("") //jim@tracknicity.com
  const [password, setPassword] = useState("") //0522MAy!
  const [emailError, setEmailError] = useState(null)
  const [passwordError, setPasswordError] = useState(null)
  const [loginDisabled, setLoginDisabled] = useState(true)
  const [loading, setLoading] = useState(false)

  const rayzeLogo = require("assets/rayzeLogo.png")

  useEffect(() => {
    setEmailError(null)
    setPasswordError(null)

    //check valid email here
    checkIfLoginActive()
  }, [email, password])

  useEffect(() => {
    setLoading(false)
  }, [emailError, passwordError])

  const checkIfLoginActive = () => {
    if (email !== "" && password !== "") {
      setLoginDisabled(false)
    } else {
      setLoginDisabled(true)
    }
  }

  const loginPressed = () => {
    setLoading(true)
    login(email, password, setEmailError, setPasswordError)
  }

  function forgotPasswordPressed() {
    const email = window.prompt("Enter your email address")
    if (email) {
      sendPasswordReset(email)
    }
  }

  return (
    <View style={styles.container}>
      <View style={styles.body}>
        <View style={styles.imageContainer}>
          <Image source={rayzeLogo} style={{ width: 238, height: 168 }} />
        </View>
        <View style={styles.bodyContainer}>
          {/* <View> */}
          <View style={styles.inputContainer}>
            <CustomInput
              // title={'Email'}
              placeholder={"Enter email address"}
              value={email}
              errorMessage={emailError}
              onChangeText={(e) => setEmail(e)}
              style={{ marginBottom: 24 }}
            />
            <CustomInput
              // title={'Password'}
              placeholder={"Enter password"}
              value={password}
              errorMessage={passwordError}
              onChangeText={(e) => setPassword(e)}
              secure={true}
            />
          </View>
          <TouchableOpacity onPress={() => forgotPasswordPressed()}>
            <Text style={styles.forgotPassword}>Forgot Password?</Text>
          </TouchableOpacity>

          <TouchableHighlight
            style={[
              styles.loginButton,
              loginDisabled ? styles.inactive : styles.active,
            ]}
            underlayColor={"#DDD"}
            onPress={() => loginPressed()}
            disabled={loginDisabled}
          >
            <PrimaryText
              fontSize={20}
              fontWeight={600}
              style={styles.loginButtonText}
            >
              Sign In
            </PrimaryText>
          </TouchableHighlight>
          {/* </View> */}
        </View>
      </View>
      {loading && (
        <ActivityIndicator
          animating={loading}
          size={"large"}
          style={StyleSheet.absoluteFill}
          color={COLORS.primary.blue}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    flexDirection: "column",
    backgroundColor: COLORS.primary.white,
    justifyContent: "center",
    alignItems: "center",
  },
  body: {
    minWidth: 500,
  },
  imageContainer: {
    // flex: 1,
    alignItems: "center",
    marginBottom: 48,
  },
  loginText: {
    fontSize: 20,
    fontWeight: "700",
    textAlign: "center",
    color: COLORS.primary.lightBlue,
  },
  bodyContainer: {
    flex: 1,
    marginTop: 0,
    paddingHorizontal: 24,
    alignContent: "center",
    justifyContent: "space-between",
  },
  inputContainer: {
    flexDirection: "column",
    paddingTop: 12,
    paddingBottom: 8,
  },
  forgotPassword: {
    justifyContent: "flex-end",
    alignContent: "flex-end",
    textAlign: "right",
    color: COLORS.primary.lightBlue,
    fontSize: 14,
    fontWeight: "700",
  },
  loginButton: {
    marginVertical: 40,
    height: 37,
    width: 235,
    borderRadius: 2,
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    backgroundColor: COLORS.primary.blue,
  },
  active: {
    opacity: 1,
  },
  inactive: {
    // opacity: 0.5,
    backgroundColor: COLORS.primary.lightGray,
  },
  loginButtonText: {
    color: COLORS.primary.white,
  },
  termsText: {
    textAlign: "center",
    color: COLORS.primary.black,
    fontSize: 14,
    fontWeight: "400",
    flexWrap: "nowrap",
  },
  termsButtonText: {
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    color: COLORS.primary.lightBlue,
    flexWrap: "nowrap",
  },
})
