import React, { useEffect, useState } from "react";
import { PrimaryText } from "components/Common/PrimaryText";
import { ProfilePic } from "components/Pics/ProfilePic";
import { TableCell, TableRow } from "@mui/material";
import { getUser } from "services/user-service";
import { dollarFormatter, getUsersName } from "utils/helpers";

export const FundraisingCard = ({ item, onPress }) => {
  const { userId, userEmail, totalDonations, lastUpdated, backers, org, user, active, cot } = item;

  return (
    <>
      {(!!userId && !user) ? null : (
        <TableRow hover onClick={onPress}>
          <TableCell width={36}>
            <ProfilePic uri={user?.profilePic} size={36} />
          </TableCell>
          <TableCell style={{ textAlign: "left" }}>
            <PrimaryText fontSize={18} fontWeight={900}>
              {user ? getUsersName(user) : item?.name}
            </PrimaryText>
          </TableCell>
          <TableCell>
            <PrimaryText fontSize={16} fontWeight={500}>
              {org?.name}
            </PrimaryText>
          </TableCell>
          <TableCell>
            <PrimaryText fontSize={16} fontWeight={500}>
              {active ? "x" : ""}
            </PrimaryText>
          </TableCell>
          <TableCell>
            <PrimaryText fontSize={16} fontWeight={500}>
              {cot ? "x" : ""}
            </PrimaryText>
          </TableCell>
          <TableCell>
            <PrimaryText fontSize={16} fontWeight={500}>
              {backers?.length || 0}
            </PrimaryText>
          </TableCell>
          <TableCell>
            <PrimaryText fontSize={16} fontWeight={500}>
              {dollarFormatter.format(totalDonations)}
            </PrimaryText>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
