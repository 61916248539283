import React from "react";
import { PrimaryText } from "components/Common/PrimaryText";
import { TableCell, TableRow } from "@mui/material";
import { OrgPic } from "components/Pics/OrgPic";
import { COLORS } from "constants/Colors";
import { dollarFormatter, getCityStateZip } from "utils/helpers";
import { FiDollarSign } from "react-icons/fi";
import { View } from "react-native-web";

export const OrgCard = ({ org, onPress }) => {
	const {
		profilePic,
		name,
		members,
		verified = false,
		stripeComplete = false,
		totalDonations = 0,
		createdAt = new Date(),
		stripeID = "",
		pendingVerification = false
	} = org;

	return (
		<TableRow
			hover
			onClick={onPress}
		>
			<TableCell width={36}>
				<OrgPic
					uri={profilePic}
					size={36}
				/>
			</TableCell>
			<TableCell>
				<PrimaryText
					fontSize={12}
					fontWeight={500}
					style={{ color: COLORS.primary.lightBlue }}
				>
					{name}
				</PrimaryText>
			</TableCell>
			<TableCell>
				<PrimaryText
					fontSize={12}
					fontWeight={400}
				>
					{members?.length}
				</PrimaryText>
			</TableCell>
			<TableCell>
				<View
					style={{
						flexDirection: "row",
						justifyContent: "center",
						alignItems: "flex-end"
					}}
				>

					<PrimaryText
						fontSize={12}
						fontWeight={400}
						style={{ color: stripeComplete ? COLORS.primary.green : stripeID ? COLORS.primary.orange : COLORS.primary.error }}
					>
						{stripeComplete ? "Accepting Donations" : stripeID ? "Pending Approval" : "Not Started"}
					</PrimaryText>
				</View>
			</TableCell>
			<TableCell>
				<View
					style={{
						flexDirection: "row",
						justifyContent: "center",
						alignItems: "flex-end"
					}}
				>

					<PrimaryText
						fontSize={12}
						fontWeight={400}
						style={{ color: verified ? COLORS.primary.green : pendingVerification ? COLORS.primary.orange : COLORS.primary.error }}
					>
						{verified ? "Verified" : pendingVerification ? "Pending Verification" : "Unverified"}
					</PrimaryText>
				</View>
			</TableCell>
			<TableCell>
				<PrimaryText
					fontSize={12}
					fontWeight={400}
				>
					{getCityStateZip(org)}
				</PrimaryText>
			</TableCell>
			<TableCell>
				<PrimaryText
					fontSize={12}
					fontWeight={400}
				>
					{dollarFormatter.format(totalDonations)}
				</PrimaryText>
			</TableCell>
			<TableCell>
				<PrimaryText
					fontSize={12}
					fontWeight={400}
				>
					{createdAt?.toDate?.()?.toDateString?.()}
				</PrimaryText>
			</TableCell>
		</TableRow>
	);
};
