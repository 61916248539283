import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState
} from "react";
import { getOurCauses } from "services/causes-service";
import {
	fetchAllNonprofits,
	getOrgMembers
} from "services/organizations-service";
import { useAuth } from "./AuthContext";
import { useCauses } from "./CausesContext";
import { getCOTBearer } from "services/explore-service";

export const nonprofitFilterOptions = [
	{ value: "all", label: "All" },
	{ value: "unverified", label: "Unverified" },
	{ value: "pending", label: "Pending" },
	{ value: "verified", label: "Verified" },
];

export const nonprofitStripeFilterOptions = [
	{ value: "all", label: "All" },
	{ value: "notStarted", label: "Not Started" },
	{ value: 'stripePending', label: "Stripe Pending" },
	{ value: 'acceptingDonations', label: "Accepting Donations" },
];

const OrganizationContext = createContext();

export const OrganizationProvider = (props) => {
	const { currentUser } = useAuth();
	const { allCauses } = useCauses();

	const [lastDoc, setLastDoc] = useState(null);
	const [orgs, setOrgs] = useState([]);
	const [getOrgs, setGetOrgs] = useState(false);
	const [nonProfits, setNonprofits] = useState([]);
	const [page, setPage] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [orgsCount, setOrgsCount] = useState(0);

	const [selectedOrg, setSelectedOrg] = useState(null);
	const [members, setMembers] = useState([]);
	const [causes, setCauses] = useState([]);
	const [owner, setOwner] = useState(false);
	const [userType, setUserType] = useState("");
	const [currentMember, setCurrentMember] = useState(null);
	const [sponsors, setSponsors] = useState([]);
	const [coverPhoto, setCoverPhoto] = useState(null);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [sorting, setSorting] = useState({
		orderBy: 'name',
		orderIn: 'asc'
	});
	const [selectedView, setSelectedView] = useState(nonprofitFilterOptions[0]?.value);
	const [selectedStripeFilter, setSelectedStripeFilter] = useState(nonprofitStripeFilterOptions[0]?.value);
	const [search, setSearch] = useState("");

	const [cotBearer, setCOTBearer] = useState(null);

	useEffect(() => {
		if (!getOrgs) return;
		fetchAllNonprofits(setOrgs)
	}, [getOrgs]);

	useEffect(() => {
		if (search) {
			setPage(0);
			const filteredOrgs = orgs.filter((org) => org.name.toLowerCase().includes(search.toLowerCase())
				|| org.ein.toLowerCase().includes(search.toLowerCase())
				|| (org?.address?.city && org?.address?.city.toLowerCase().includes(search.toLowerCase()))
				|| (org?.address?.state && org?.address?.state.toLowerCase().includes(search.toLowerCase()))
				|| (org?.address?.zipCode && org?.address?.zipCode.toLowerCase().includes(search.toLowerCase()))
		);
			filterOrgs(filteredOrgs);
		} else {
			filterOrgs(orgs);
		}
	}, [search, orgs, sorting, selectedView, selectedStripeFilter]);


	function filterOrgs(orgs) {
		let filteredOrgs = [...orgs];
		if (selectedView === "verified") {
			setPage(0);
			filteredOrgs = filteredOrgs.filter((org) => org.verified && !org.stripeComplete);
		} else if (selectedView === "unverified") {
			setPage(0);
			filteredOrgs = filteredOrgs.filter((org) => !org.verified && !org?.pendingVerification);
		} else if (selectedView === "pending") {
			setPage(0);
			filteredOrgs = filteredOrgs.filter((org) => !org.verified && org?.pendingVerification);
		}



		if (selectedStripeFilter === "acceptingDonations") {
			setPage(0);
			filteredOrgs = filteredOrgs.filter((org) => org.stripeComplete && org.verified);
		} else if (selectedStripeFilter === "stripePending") {
			setPage(0);
			filteredOrgs = filteredOrgs.filter((org) => org.verified && !org.stripeComplete && org.stripeID);
		} else if (selectedStripeFilter === "notStarted") {
			setPage(0);
			filteredOrgs = filteredOrgs.filter((org) => !org.stripeID);
		}

		//sorting
		if (sorting.orderBy && sorting.orderIn) {
			filteredOrgs = filteredOrgs.sort((a, b) => {
				if (sorting.orderIn === "asc") {
					if (a[sorting.orderBy] < b[sorting.orderBy]) {
						return -1;
					}
					if (a[sorting.orderBy] > b[sorting.orderBy]) {
						return 1;
					}
					return 0;
				} else {
					if (a[sorting.orderBy] > b[sorting.orderBy]) {
						return -1;
					}
					if (a[sorting.orderBy] < b[sorting.orderBy]) {
						return 1;
					}
					return 0;
				}
			});
		}

		setNonprofits(filteredOrgs);
		setOrgsCount(filteredOrgs.length);
	}

	useEffect(() => {
		if (!selectedOrg) {
			setMembers([]);
			setOwner(false);
			setUserType("");
			setSponsors([]);
			setCoverPhoto(null);
			return;
		}

		setCoverPhoto(selectedOrg.coverPhoto);

		const memberSub = getOrgMembers(
			currentUser,
			selectedOrg,
			setMembers,
			setSponsors,
			setUserType,
			setCurrentMember,
			() => {}
		);

		return () => {
			memberSub();
		};
	}, [selectedOrg]);

	//set our causes
	useEffect(() => {
		if (!selectedOrg || !allCauses) return;
		getOurCauses(selectedOrg, allCauses, setCauses);
	}, [selectedOrg, allCauses]);

	useEffect(() => {
		if (coverPhoto && selectedOrg) {
			selectedOrg.coverPhoto = coverPhoto;
		}
	}, [coverPhoto]);

	async function fetchData() {
		const bearer = await getCOTBearer();
		setCOTBearer(bearer);
	}

	useEffect(() => {
		fetchData();
	}, []);

	function nextOrg() {
		const index = nonProfits.findIndex((org) => org.id === selectedOrg.id);
		if (index === nonProfits.length - 1) {
			return setSelectedOrg(nonProfits[0]);
		}
		setSelectedOrg(nonProfits[index + 1]);
	}

	function prevOrg() {
		const index = nonProfits.findIndex((org) => org.id === selectedOrg.id);
		if (index === 0) {
			console.log("index", index);
			return setSelectedOrg(nonProfits[nonProfits.length - 1]);
		}
		setSelectedOrg(nonProfits[index - 1]);
	}

	return (
		<OrganizationContext.Provider
			value={{
				orgs,
				setOrgs,
				lastDoc,
				setLastDoc,
				getOrgs,
				setGetOrgs,
				selectedOrg,
				setSelectedOrg,
				members,
				setMembers,
				currentMember,
				causes,
				setCauses,
				coverPhoto,
				setCoverPhoto,
				page,
				setPage,
				rowsPerPage,
				setRowsPerPage,
				sorting,
				setSorting,
				orgsCount,
				cotBearer,
				selectedView,
				setSelectedView,
				search,
				setSearch,
				nonProfits, setNonprofits,
				nextOrg,
				prevOrg,
				selectedStripeFilter, setSelectedStripeFilter,
				filterOrgs
			}}
		>
			{props.children}
		</OrganizationContext.Provider>
	);
};

export const useOrganization = () => useContext(OrganizationContext);
