import React, { useState } from "react"
import styled from "styled-components"
import * as FaIcons from "react-icons/fa"
import { SidebarData } from "./SidebarData"
import { SubMenu } from "./SubMenu"
import { Image, StyleSheet, TouchableOpacity, View } from "react-native"
import { COLORS } from "constants/Colors"
import { PrimaryText } from "components/Common/PrimaryText"
import { useAuth } from "context/AuthContext"
import { logout } from "services/auth-service"
import { useDisplay } from "context/DisplayContext"
import { getUsersName } from "utils/helpers"
import { ROLES } from "constants/Data"
import { useRayze } from "context/RayzeContext"

const SidebarNav = styled.nav`
  background: ${COLORS.primary.offWhite};
  width: ${({ sidebar }) => (sidebar ? "280px" : "60px")};
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  transition: 350ms;
  z-index: 10;
  font-family: Montserrat, Arial, serif;
  overflow: scroll;
`

const Sidebar = () => {
  const { currentUser } = useAuth()
  const { userType } =
    useRayze()
  const { showSidebar, setShowSidebar } = useDisplay()

  const [subnav, setSubnav] = useState(false)
  const [showProfileMenu, setShowProfileMenu] = useState(false)

  const rayzeLogo = require("assets/rayzeLogo.png")

  function logoutPressed() {
    logout()
    window.location.reload(false)
  }

  return (
    <>
      {/** TOP NAV */}
      <View style={[styles.topBar, { marginLeft: showSidebar ? 280 : 60 }]}>
        {/* <TouchableOpacity style={styles.notificationContainer}>
          <FaIcons.FaBell size={20} color={COLORS.primary.white} />
        </TouchableOpacity> */}

        <TouchableOpacity
          style={styles.userContainer}
          onPress={() => setShowProfileMenu(!showProfileMenu)}
        >
          <View style={styles.userIcon}>
            {currentUser?.profilePic ? (
              <Image
                source={currentUser?.profilePic}
                style={styles.profilePic}
              />
            ) : (
              <FaIcons.FaUser size={20} color={COLORS.primary.white} />
            )}
          </View>
          <View style={{ flex: 1, marginHorizontal: 24 }}>
            <PrimaryText
              fontSize={14}
              fontWeight={300}
              style={styles.userName}
              numberOfLines={1}
            >
              {getUsersName(currentUser)}
            </PrimaryText>
            {!!userType && (
              <PrimaryText
                fontSize={12}
                fontWeight={300}
                style={styles.userName}
                numberOfLines={1}
              >
                {ROLES.find((x) => x.value === userType)?.label}
              </PrimaryText>
            )}
          </View>
          <FaIcons.FaChevronDown size={20} color={COLORS.primary.darkGray} />
        </TouchableOpacity>
      </View>

      <SidebarNav sidebar={showSidebar}>
        <View
          style={{
            width: "100%",
            justifyContent: "space-between",
            paddingBottom: 12,
          }}
        >
          <View>
            <View style={styles.companyInfoContainer}>
              <View
                style={[
                  styles.rayzeContainer,
                  !showSidebar && { justifyContent: "center" },
                ]}
              >
                <Image
                  source={rayzeLogo}
                  style={[
                    styles.rayzeLogo,
                    !showSidebar && { marginBottom: 0, height: 30, width: 60 },
                  ]}
                />
              </View>
            </View>
            {SidebarData.map((item, index) => {
              return (
                <SubMenu
                  subnav={subnav}
                  setSubnav={setSubnav}
                  item={item}
                  key={index}
                  currentUser={currentUser}
                  showSidebar={showSidebar}
                  setShowSidebar={setShowSidebar}
                />
              )
            })}
          </View>

          {/** SHOW SIDEBAR BUTTON */}
          <TouchableOpacity
            style={{
              alignSelf: showSidebar ? "flex-end" : "center",
              marginRight: showSidebar ? 12 : 0,
            }}
            onPress={() => setShowSidebar(!showSidebar)}
          >
            {showSidebar ? (
              <FaIcons.FaChevronLeft size={20} color={COLORS.primary.darkBlue} />
            ) : (
              <FaIcons.FaChevronRight size={20} color={COLORS.primary.darkBlue} />
            )}
          </TouchableOpacity>
        </View>
      </SidebarNav>
      {showProfileMenu && (
        <View style={styles.profileMenu}>
          <TouchableOpacity style={styles.menuButton} onPress={logoutPressed}>
            <PrimaryText
              fontSize={12}
              fontWeight={300}
              style={styles.userName}
              numberOfLines={1}
            >
              Logout
            </PrimaryText>
            <FaIcons.FaSignOutAlt size={20} color={COLORS.primary.darkGray} />
          </TouchableOpacity>
        </View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  companyInfoContainer: {
    marginVertical: 12,
    marginHorizontal: 12,
    zIndex: 999,
  },
  rayzeContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  rayzeLogo: {
    height: 92,
    width: 132,
    resizeMode: "contain",
    marginVertical: 30,
  },

  companyName: {
    fontSize: 16,
    color: COLORS.primary.black,
    marginVertical: 12,
    textAlign: "center",
  },
  showSidebarButton: {
    alignSelf: "center",
  },

  //top bar
  topBar: {
    flexDirection: "row",
    paddingHorizontal: 12,
    backgroundColor: COLORS.primary.offWhite,
    height: 80,
    justifyContent: "flex-end",
    alignItems: "center",

    // shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 2,
    // },
    // shadowOpacity: 0.25,
    // shadowRadius: 3.84,
  },
  notificationContainer: {
    backgroundColor: COLORS.primary.green,
    height: 36,
    width: 36,
    borderRadius: 18,
    justifyContent: "center",
    alignItems: "center",
  },
  userContainer: {
    flexDirection: "row",
    // justifyContent:'space-around',
    alignItems: "center",
  },
  userIcon: {
    backgroundColor: COLORS.primary.darkGray,
    height: 36,
    width: 36,
    borderRadius: 18,
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
  },
  profilePic: {
    width: "100%",
    height: "100%",
    resizeMode: "cover",
  },
  userName: {
    fontSize: 14,
    lineHeight: 24,
    color: COLORS.primary.darkGray,
  },
  profileMenu: {
    position: "absolute",
    zIndex: 1,
    backgroundColor: COLORS.primary.white,
    right: 0,
    top: 80,
    height: 40,
    width: 200,
    paddingHorizontal: 12,

    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 3.5,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  menuButton: {
    flexDirection: "row",
    width: "100%",
    height: 40,
    justifyContent: "space-between",
    alignItems: "center",
  },
})

export default Sidebar
