import React, { useState } from "react"
import { PrimaryText } from "components/Common/PrimaryText"
import { COLORS } from "constants/Colors"
import { StyleSheet, View } from "react-native"
import { Link } from "react-router-dom"

export const InspiringTab = () => {
  const [inspiring, setInspiring] = useState([])

  return (
    <View style={styles.tableContainer}>

      <div className="d-flex align-items-center pt-2 pl-3"></div>

      <table className="table table-borderless">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">Name</th>
            <th scope="col">Engagements</th>
            <th scope="col">Location</th>
            <th scope="col" style={{ fontWeight: "bold" }}>
              Follow Date
              <span className="ml-2">
                <i className="fas fa-arrow-up"></i>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {inspiring &&
            inspiring.map((el, i) => {
              const { img, name, date, mode, meetingURL, openings } = el

              return (
                <tr key={i}>
                  <td>
                    <div className="img-round m-auto">
                      <img src={img} alt="" />
                    </div>
                  </td>
                  <td>{name}</td>
                  <td>
                    {mode.toLowerCase() === "virtual" ? (
                      <Link to={meetingURL}>{meetingURL}</Link>
                    ) : (
                      mode
                    )}
                  </td>
                  <td></td>
                  <td>{openings && openings.length}</td>
                  <td>{date}</td>
                </tr>
              )
            })}
        </tbody>
      </table>
    </View>
  )
}

const styles = StyleSheet.create({
  tableContainer: {
    minHeight: 300,
    backgroundColor: COLORS.primary.offWhite,
    borderRadius: 12,
    borderTopLeftRadius: 0,
    paddingVertical: 12,
  },
  headerContainer: {
    flexDirection: "row",
  },
  infoContainer: {
    backgroundColor: COLORS.primary.white,
    marginHorizontal: 12,
    borderRadius: 12,
    paddingHorizontal: 20,
    paddingVertical: 12,
    alignItems: "center",
    justifyContent: "center",
  },
  info: {
    alignItems: "center",
    justifyContent: "center",
    marginHorizontal: 24,
  },
  title: {
    marginBottom: 12,
  },
})
