import React, { useEffect, useState } from "react";
import { StyleSheet, ActivityIndicator } from "react-native";
import { COLORS } from "constants/Colors";
import { PrimaryText } from "components/Common/PrimaryText";
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from "@mui/material";
import { ShadowContainer } from "components/Containers/ShadowContainer";
import MuiTablePagination from "components/Common/MuiTablePagination";
import { ProfilePic } from "components/Pics/ProfilePic";
import { CustomInput } from "components/Inputs/CustomInput";
import { DateInput } from "components/Inputs/DateInput";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { FiUpload } from "react-icons/fi";
import { useFundraisers } from "context/FundraiserContext";
import { useOrganization } from "context/OrganizationContext";
import { FundraisingCard } from "components/Cards/FundraisingCard";
import { useNavigate } from "react-router-dom";
import { Links } from "constants/Links";
import exportCSV from "utils/export-csv";
import moment from "moment";
import { dollarFormatter } from "utils/helpers";



export function Fundraising() {
	const navigate = useNavigate();
	const { setGetFundraisers, setSelectedFundraiser, allFundraisers } =
		useFundraisers();
	const { selectedOrg } = useOrganization();

	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState("");
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [filtered, setFiltered] = useState([]);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(0);

	useEffect(() => {
		setGetFundraisers(true);
		let temp = [...allFundraisers];
		if (search) {
			temp = temp.filter(
				(item) =>
					item?.userEmail?.toLowerCase().includes(search.toLowerCase()) ||
					item?.user?.firstName?.toLowerCase().includes(search.toLowerCase()) ||
					item?.user?.lastName?.toLowerCase().includes(search.toLowerCase()) ||
					item?.org?.name?.toLowerCase().includes(search.toLowerCase()) ||
					item?.totalDonations.toString().includes(search)
			);
		}
		if (startDate) {
			temp = temp.filter((item) => item.lastUpdated >= startDate);
		}
		if (endDate) {
			temp = temp.filter((item) => item.lastUpdated <= endDate);
		}
		setFiltered(temp);
	}, [search, startDate, endDate, allFundraisers]);

	const resetFilters = () => {
		setSearch("");
		setStartDate(null);
		setEndDate(null);
	};

	const currentTableData = () => {
		const firstPageIndex = currentPage * rowsPerPage;
		const lastPageIndex = firstPageIndex + rowsPerPage;
		return filtered.slice(firstPageIndex, lastPageIndex);
	};

	// Export fundraisers in CSV
	const exportFundraisers = () => {
		const currentData = currentTableData();

		if (!currentData?.length) return;

		const dataToExport = currentData.map((row) => {
			return {
				NAME: row?.user ? `${row?.user?.firstName} ${row?.user?.lastName}` : row?.name,
				ORGANIZATION: row?.org?.name,
				ACTIVE: row?.active ? "Y" : "",
				BACKERS: row?.backers?.length,
				"AMOUNT RAISED": dollarFormatter.format(row?.totalDonations)
			};
		});

		exportCSV({
			data: dataToExport,
			filename: "Fundraisers"
		});
	};

	console.log(filtered?.length, rowsPerPage, currentPage);

	return (
		<>
			<ShadowContainer style={styles.container}>
				{/** HEADER */}
				<PrimaryText
					fontSize={32}
					fontWeight={900}
					style={styles.title}
				>
					Champions
				</PrimaryText>

				<Box
					display="flex"
					alignItems="center"
					justifyContent="space-between"
					width="100%"
					mb={40}
					mt={14}
				>
					<CustomInput
						placeholder="Search"
						style={{
							flexGrow: 1,
							maxWidth: 350
						}}
						value={search}
						onChangeText={setSearch}
					/>

				

					{/* Export Button */}
					<PrimaryButton
						title="Export Report"
						onPress={exportFundraisers}
						icon={
							<FiUpload
								color="white"
								style={{ marginRight: 10 }}
							/>
						}
						style={{ marginBottom: 0 }}
					/>
				</Box>

				{/** TABLE */}
				<Table
					className="table"
					style={{ borderTopLeftRadius: 12, overflow: "hidden" }}
				>
					<TableHead>
						<TableRow>
							<TableCell></TableCell>
							<TableCell style={{ textAlign: "left" }}>Name</TableCell>
							<TableCell>Nonprofit</TableCell>
							<TableCell>Active</TableCell>
							<TableCell>COT</TableCell>
							<TableCell>Donors</TableCell>
							<TableCell>Amount Raised</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{currentTableData() &&
							currentTableData().map((item) => (
								<FundraisingCard
									key={item.id}
									item={item}
									onPress={() => {
										setSelectedFundraiser(item);
										navigate(Links.FundraisingDetail);
									}}
								/>
							))}
					</TableBody>
				</Table>

				<MuiTablePagination
					count={filtered?.length}
					rowsPerPage={rowsPerPage}
					page={currentPage}
					setCurrentPage={setCurrentPage}
					onRowsPerPageChange={(e) => {
						setRowsPerPage(e.target.value);
						setCurrentPage(0);
					}}
				/>
			</ShadowContainer>
			{loading && (
				<ActivityIndicator
					animating={loading}
					size={"large"}
					style={styles.activity}
					color={COLORS.primary.lightBlue}
				/>
			)}
		</>
	);
}

// Temporary table rows
const Rows = () => (
	<TableRow>
		<TableCell
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center"
			}}
		>
			<ProfilePic size={50} />
			<PrimaryText
				fontSize={20}
				fontWeight={900}
				style={{ marginLeft: 14 }}
			>
				John Doe
			</PrimaryText>
		</TableCell>

		{/* Email */}
		<TableCell>
			<PrimaryText
				fontSize={16}
				fontWeight={400}
				style={{ color: COLORS.primary.blue }}
			>
				email@address.com
			</PrimaryText>
		</TableCell>

		{/* Latest donation */}
		<TableCell>
			<PrimaryText
				fontSize={16}
				fontWeight={400}
			>
				01/01/2023
			</PrimaryText>
		</TableCell>

		{/* Backers */}
		<TableCell>
			<PrimaryText
				fontSize={16}
				fontWeight={400}
			>
				195
			</PrimaryText>
		</TableCell>

		{/* Amount raised */}
		<TableCell>
			<PrimaryText
				fontSize={16}
				fontWeight={400}
			>
				$182,029
			</PrimaryText>
		</TableCell>
	</TableRow>
);

const styles = StyleSheet.create({
	activity: {
		width: "100%",
		height: "100%",
		backgroundColor: COLORS.primary.white,
		position: "absolute",
		top: 0,
		bottom: 0
	},
	container: {
		flex: 1,
		width: "100%",
		flexDirection: "column"
	}
});
