import React, { useEffect, useState } from "react";
import { StyleSheet, View, Image } from "react-native";
import { COLORS } from "constants/Colors";
import { useDisplay } from "context/DisplayContext";
import { getOrgProfilePicture } from "services/organizations-service";

const rayzeIcon = require("assets/misc/default_org_dp.png");

export const OrgPic = (props) => {
  const { darkMode } = useDisplay();
  const [imageUri, setImageUri] = useState(null);

  useEffect(() => {
    setImageUri(props.uri);
    props.orgId && getOrgProfilePicture(props.orgId, setImageUri);
  }, [props.uri, props.userId]);

  const style = styles(darkMode);

  const borderSize = props.size - 3;
  const picSize = props.size - 5;

  return (
    <View
      style={[
        style.innerContainer,
        { width: borderSize, height: borderSize, borderRadius: borderSize / 2 },
      ]}
    >
      <Image
        style={{ width: picSize, height: picSize, borderRadius: picSize / 2 }}
        resizeMode={"contain"}
        source={imageUri ? { uri: imageUri } : rayzeIcon}
      />
    </View>
  );
};

const styles = (darkMode) =>
  StyleSheet.create({
    container: {
      aspectRatio: 1,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: COLORS.primary.white,
    },
    innerContainer: {
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: darkMode
        ? COLORS.primary.darkBlue
        : COLORS.primary.white,
    },
  });
