import { firestore } from "utils/firebase"

export const listenForRayzeData = (setRayzeStats) => {
  return firestore
    .collection("rayze")
    .doc("data")
    .onSnapshot(
      (doc) => {
        const data = {
          id: doc.id,
          ...doc.data(),
        }

        setRayzeStats(data)
      },
      (e) => {
        console.log("listenForRayzeData err", e)
      }
    )
}
