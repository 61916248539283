import React, { useState, useEffect } from "react";
import { StyleSheet, View } from "react-native";

import { COLORS } from "constants/Colors";
import { saveContact } from "services/crm-service";
import { ClaimForm } from "components/Claim/ClaimForm";
import MuiButton from "components/Common/MuiButton";
import { Dimensions, Image } from "react-native-web";

const { height } = Dimensions.get("window");

export const Claim = () => {
  const [newContact, setNewContact] = useState({
    status: 1,
    leadOwner: "jQC2dOt9NQRYUwFwXXbrBSySLq63",
  });

  const [showNewContactModal, setShowNewContactModal] = useState(false);

  useEffect(() => {
    if (!showNewContactModal) {
      setNewContact({
        status: 1,
        leadOwner: "jQC2dOt9NQRYUwFwXXbrBSySLq63",
      });
    }
  }, [showNewContactModal])

  function saveNewContact() {
    saveContact(newContact, (contact) => {
      setShowNewContactModal(false);
      setNewContact({ status: 1, leadOwner: "jQC2dOt9NQRYUwFwXXbrBSySLq63" });
    });
  }

  console.log("showNewContactModal", showNewContactModal);

  return (
    <>
      {!showNewContactModal ? (
        <View style={[styles.container, {background: "linear-gradient(270.04deg, #1DA1F2 0.03%, #46D7F7 98.22%)"}]}>
          <Image
            source={require("assets/rayzeLogo.png")}
            style={{ width: 300, height: 300 }}
            resizeMode="contain"
          />
          <MuiButton
            title="Claim a Nonprofit"
            onPress={() => setShowNewContactModal(!showNewContactModal)}
            variant="contained"
            sx={{ pY: 6, fontSize: 14, fontWeight: 700, mr: 20, ml: 20 }}
          />
        </View>
      ) : (
        <ClaimForm
          title={"Search Nonprofits"}
          contact={newContact}
          setContact={setNewContact}
          showModal={showNewContactModal}
          setShowModal={setShowNewContactModal}
          onSave={saveNewContact}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    width: "100%",
    height: height,
    alignItems: "center",
    justifyContent: "center",
  },
});
