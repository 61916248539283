import React from "react"
import { PrimaryText } from "components/Common/PrimaryText"
import { TableCell, TableRow } from "@mui/material"
import { GiTrashCan } from "react-icons/gi"
import { TouchableOpacity } from "react-native-web"
import { COLORS } from "constants/Colors"

export const CauseSkillsCard = ({ item, onDelete }) => {
  const { name } = item

  return (
    <TableRow hover onClick={() => console.log("user clicked", item)}>
      <TableCell>
        <PrimaryText
          fontSize={16}
          fontWeight={400}
          style={{ textAlign: "right" }}
        >
          {name}
        </PrimaryText>
      </TableCell>

      <TableCell>
        <PrimaryText fontSize={16} fontWeight={400}>
          ##
        </PrimaryText>
      </TableCell>

      <TableCell>
        <PrimaryText fontSize={16} fontWeight={400}>
          ##
        </PrimaryText>
      </TableCell>

      <TableCell>
        <PrimaryText fontSize={16} fontWeight={400}>
          ##
        </PrimaryText>
      </TableCell>

      <TableCell>
        <TouchableOpacity onPress={onDelete}>
          <GiTrashCan size={20} color={COLORS.primary.error} />
        </TouchableOpacity>
      </TableCell>
    </TableRow>
  )
}
