import React, { useEffect, useState } from "react"
import { PrimaryText } from "components/Common/PrimaryText"
import { ProfilePic } from "components/Pics/ProfilePic"
import { TableCell, TableRow } from "@mui/material"
import { getUser } from "services/user-service"
import { getUsersName } from "utils/helpers"

export const DonationCard = ({ item }) => {
  const { userId, amount, date, recurring } = item

  const [user, setUser] = useState(null)

  useEffect(() => {
    userId && getUser(userId, setUser)
  }, [userId])

  return (
    <TableRow hover onClick={() => console.log('donation clicked', item)}>
      <TableCell>
        <ProfilePic uri={user?.profilePic} size={36} />
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={400}>
          {user ? getUsersName(user) : item?.name}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={400}>
          {recurring ? "recurring" : "one-time"}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={400}>
          {amount}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={400}>
          {date.toDate().toDateString()}
        </PrimaryText>
      </TableCell>
    </TableRow>
  )
}
