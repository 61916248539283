import React, { useState, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import FormInput from "components/Partials/FormInput";
import { useUsers } from "context/UserContext";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { COLORS } from "constants/Colors";
import { UserCard } from "components/Cards/UserCard";
import { PrimaryText } from "components/Common/PrimaryText";
import { useDashboard } from "context/DashboardContext";
import { useNavigate } from "react-router-dom";
import { Links } from "constants/Links";
import * as FaIcons from "react-icons/fa";
import { TouchableOpacity } from "react-native-web";
import { searchUsers } from "services/user-service";

const pageSize = 10;

export const Users = (props) => {
  const navigate = useNavigate();
  const {
    users = [],
    setGetUsers,
    setSelectedUser,
    page,
    setPage,
    nextPage,
  } = useUsers();
  const { rayzeStats } = useDashboard();

  const [listUsers, setListUsers] = useState(users);
  const [search, setSearch] = useState("");

  useEffect(() => {
    setGetUsers(true);
  }, []);

  useEffect(() => {
    if (search) {
      setPage(1);
      searchUsers(search, setListUsers);
    } else {
      setListUsers(users);
    }
  }, [search, users]);

  function userSelected(user) {
    setSelectedUser(user);
    navigate(Links.UserDetail);
  }

  const currentTableData = () => {
    const firstPageIndex = (page - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return listUsers.slice(firstPageIndex, lastPageIndex);
  };

  const totalCount = search ? listUsers?.length : rayzeStats?.activeUsers;
  const totalPageCount = Math.ceil(totalCount / pageSize);
  const startCount = (page - 1) * pageSize + 1;
  const endCount = startCount + currentTableData()?.length - 1;

  return (
    <View style={styles.tableContainer}>
      {/* <div className="main-content manageAdmin"> */}
      <PrimaryText fontSize={24} fontWeight={700} style={styles.header}>
        Users
      </PrimaryText>
      <div className="flex-between-center">
        <div className="d-flex align-items-center w-60">
          <h4 className="f-14 fw-normal mb-0 mr-16">Keyword</h4>
          <div className="flex-1">
            <FormInput
              type="search"
              placeholder="i.e. name, username"
              value={search}
              setValue={setSearch}
            />
          </div>
        </div>

        {/* <CSVLink
          {...csvReport}
          className="app-button btn-primary wh-unset p-10"
          style={{ backgroundColor: "#1DA1F2" }}
        >
          Export CSV
        </CSVLink> */}
      </div>

      <Table
        className="table"
        style={{ borderTopLeftRadius: 12, overflow: "hidden" }}
      >
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Name</TableCell>
            {/* <TableCell>Organizations</TableCell> */}
            <TableCell>City</TableCell>
            <TableCell>State</TableCell>
            <TableCell>Gender</TableCell>
            <TableCell>Age</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {currentTableData() &&
            currentTableData().map((item, i) => {
              return (
                <UserCard
                  user={item}
                  key={i}
                  onPress={() => userSelected(item)}
                />
              );
            })}
        </TableBody>
      </Table>
      <View
        style={{ flexDirection: "row", alignSelf: "flex-end", marginTop: 12 }}
      >
        <TouchableOpacity onPress={() => (page > 1 ? setPage(page - 1) : null)}>
          <FaIcons.FaChevronLeft size={20} color={COLORS.primary.darkGray} />
        </TouchableOpacity>

        <PrimaryText fontSize={14} fontWeight={400} style={styles.subtitle}>
          {`${startCount} - ${endCount} of ${totalCount}`}
        </PrimaryText>

        <TouchableOpacity
          onPress={() => (page < totalPageCount ? nextPage() : null)}
        >
          <FaIcons.FaChevronRight size={20} color={COLORS.primary.darkGray} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  tableContainer: {
    width: "100%",
    // backgroundColor: COLORS.primary.offWhite,
    borderRadius: 12,
    borderTopLeftRadius: 0,
    paddingVertical: 12,
  },
  header: {
    color: COLORS.primary.darkBlue,
  },
  subtitle: {
    color: COLORS.primary.darkBlue,
    marginHorizontal: 24,
  },
});
