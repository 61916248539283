import moment from "moment";
import { firestore, auth } from "utils/firebase";
import { unique } from "utils/helpers";

export const fetchMoreContacts = async (lastDoc, setLastDoc, orgs, setOrgs) => {
  let orgRef = firestore.collection("crm").orderBy("lowercaseName").limit(10);

  let tempOrgs = [...orgs];

  if (lastDoc) {
    orgRef = firestore
      .collection("crm")
      .orderBy("lowercaseName")
      .startAfter(lastDoc.lowercaseName)
      .limit(10);
  }
  const orgSnap = (await orgRef.get().catch((e) => {
    return console.log("fetchMoreOrgs err", e);
  })) || { docs: [] };

  orgSnap.docs.map(async (item, index) => {
    tempOrgs.push({
      ...item.data(),
      id: item.id,
    });
  });
  setOrgs(tempOrgs);
  setLastDoc(tempOrgs[tempOrgs?.length - 1]);
};

export const searchContacts = (searchText, setResults) => {
  const search = searchText.toLowerCase().replace(/[^a-zA-Z ]/g, "");

  let orgQuery = firestore
    .collection("crm")
    .where("lowercaseName", ">=", search)
    .where("lowercaseName", "<=", search + "\uf8ff")
    .limit(20);

  let orgStateQuery = firestore
    .collection("crm")
    .where("address.state", ">=", search)
    .where("address.state", "<=", search + "\uf8ff")
    .limit(20);

  let orgCityQuery = firestore
    .collection("crm")
    .where("address.city", ">=", search)
    .where("address.city", "<=", search + "\uf8ff")
    .limit(20);

  let orgZipCodeQuery = firestore
    .collection("crm")
    .where("address.zipCode", ">=", searchText)
    .where("address.zipCode", "<=", searchText + "\uf8ff")
    .limit(20);

  let promises = [];
  if (search) {
    promises = [orgQuery.get(), orgStateQuery.get(), orgCityQuery.get()];
  }
  if (searchText?.length === 5) {
    promises.push(orgZipCodeQuery.get());
  }

  Promise.all(promises)
    .then((snapshots) => {
      const temp = [];
      for (const snap of snapshots) {
        for (const doc of snap.docs) {
          const data = doc.data();
          const result = {
            id: doc.id,
            ...data,
          };
          temp.push(result);
        }
      }
      let uniq = unique(temp);
      setResults(uniq);
    })
    .catch((e) => {
      console.log("error getting org search", e);
    });
};

export const getFilteredContacts = async (filter, setContacts) => {
  const startOfThisWeek = moment().startOf("week").add(1, "days").toDate();
  const twoWeeksAgo = moment(startOfThisWeek).subtract(1, "weeks").toDate();
  // console.log('startOfThisWeek', startOfThisWeek, 'twoWeeksAgo', twoWeeksAgo)
  const date =
    filter.date === "all"
      ? new Date("01-01-1980")
      : filter.date === "thisWeek"
      ? startOfThisWeek
      : twoWeeksAgo

  const { lead, status } = filter;
  let query = firestore.collection("crm").where("createdAt", ">=", date);

  if (lead) {
    query = query.where("leadOwner", "==", lead);
  }
  console.log('status', status)
  if (status) {
    query = query.where("status", "==", status);
  }

  const querySnapshot = await query.get().catch((e) => {
    console.log("error getting filtered contacts", e);
  });

  const temp = [];
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    const contact = {
      id: doc.id,
      ...data,
    };
    temp.push(contact);
  });
  setContacts(temp);
};

export const saveContact = (contact, onSuccess) => {
  const userId = auth.currentUser ? auth.currentUser.uid : "jQC2dOt9NQRYUwFwXXbrBSySLq63";
  contact.lowercaseName = contact.name.toLowerCase();
  contact.lastUpdated = new Date();

  if (contact.id) {
    firestore
      .collection("crm")
      .doc(contact.id)
      .update(contact)
      .then(() => {
        onSuccess(contact);
      })
      .catch((e) => {
        console.log("error saving contact to crm", e);
      });
  } else {
    contact.createdAt = new Date();
    contact.createdBy = userId;

    firestore
      .collection("crm")
      .add(contact)
      .then((docRef) => {
        const newContact = {
          id: docRef.id,
          ...contact,
        };
        onSuccess(newContact);
      })
      .catch((e) => {
        console.log("error saving contact to crm", e);
      });
  }
};

export const getCRMLogs = (contact, setLogs) => {
  return firestore
    .collection("crm_logs")
    .where("contactId", "==", contact.id)
    .orderBy("createdAt", "desc")
    .onSnapshot(
      (querySnapshot) => {
        let temp = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          const log = {
            id: doc.id,
            ...data,
          };
          temp.push(log);
        });
        temp = temp.sort((a, b) => a.createdAt < b.createdAt);
        setLogs(temp);
      },
      (e) => {
        console.log("error getting crm logs", e);
      }
    );
};

export const listenToCRM = (contact, setContact) => {
  return firestore
    .collection("crm")
    .doc(contact.id)
    .onSnapshot(
      (doc) => {
        const data = doc.data();
        const contact = {
          id: doc.id,
          ...data,
        };
        setContact(contact);
      },
      (e) => {
        console.log("error getting crm contact", e);
      }
    );
};
