import React from "react";
import { PrimaryText } from "components/Common/PrimaryText";
import { Checkbox, TableCell, TableRow } from "@mui/material";

export const PBCCard = ({ item, onContacted, onCheckSent, onCheckCashed, summedNet }) => {
  const { org = {}, amount, date, fee = 0 } = item;

  return (
    <TableRow>
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={400}>
          {item?.paymentId}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={400}>
          {org?.name}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={400}>
          {org?.ein}
        </PrimaryText>
      </TableCell>

      <TableCell>
        <PrimaryText fontSize={16} fontWeight={400}>
          {amount}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={400}>
          {amount - fee}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={400}>
          {summedNet}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={400}>
          {date.toDate().toDateString()}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <Checkbox checked={item?.contacted} onClick={onContacted} />
      </TableCell>
      <TableCell>
        <Checkbox
          checked={item?.onboarded}
          disableTouchRipple={true}
          sx={{
            "&:hover": { backgroundColor: "transparent" },
            pointerEvents: "none",
          }}
        />
      </TableCell>
      <TableCell>
        <Checkbox checked={item?.checkSent} onClick={onCheckSent} />
      </TableCell>
      <TableCell>
        <Checkbox checked={item?.checkCashed} onClick={onCheckCashed} />
      </TableCell>
    </TableRow>
  );
};
