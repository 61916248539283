import React, { useEffect } from "react";
import RootNavigator from "./navigators/RootNavigator";
import { AuthProvider } from "context/AuthContext";
import { DisplayProvider } from "context/DisplayContext";
import "./App.css";
import Geocode from "react-geocode";
import { Claim } from "screens/Claim/Claim";

function App() {
  useEffect(() => {
    Geocode.setApiKey("AIzaSyDR0wfcPs5HkIoR4iJTVP8K-srlpOn-Yhs");
  }, []);
  return <RootNavigator />;
}

export default () => {
  const path = window.location.pathname;
  if (path.includes("claim")) {
    return <Claim />;
  } else {
    return (
      <DisplayProvider>
        <AuthProvider>
          <App />
        </AuthProvider>
      </DisplayProvider>
    );
  }
};
