import { parseEventData } from "utils/events-helper";
import { firestore, storage, auth } from "utils/firebase";
import { getUsersName } from "utils/helpers";
import { getUsersFromMembersPromise } from "./user-service";

export const getEvents = (setAllEvents) => {
  return firestore
    .collection("events")
    .orderBy('date', 'desc')
    // .where('active', '==', true)
    .onSnapshot((querySnapshot) => {
      let upcomingEvents = []
      let pastEvents = []
      let temp = []
      querySnapshot.forEach((doc) => {
        const event = parseEventData(doc)
        temp.push(event)
        // console.log(event.startTime.getTime(), new Date() / 1000)
        // if (event.startTime < new Date() / 1000) {
        //   pastEvents.push(event)
        // } else {
        //   upcomingEvents.push(event)
        // }
      })
      setAllEvents(temp)
      // setUpcomingEvents(upcomingEvents.concat(pastEvents))
      // setPastEvents(pastEvents)
    }, e => {
      console.log("error getting events", e)
    })
}

export const createEvent = (data, coverPhotos, onSuccess) => {
  delete data.coverPhoto
  data.active = true

  firestore
    .collection("events")
    .add(data)
    .then((doc) => {
      data.id = doc.id

      coverPhotos.map((val, index) => {
        let ref = storage.ref(`eventCoverPhotos/${doc.id}/${index}.jpg`)
        ref
          .put(val.blob, { cacheControl: "max-age=31536000" })
          .then(async (result) => {
            console.log("updateEvent uploaded cover photo!", result)
            const downloadURL = await ref.getDownloadURL()
            firestore
              .collection("events")
              .doc(doc.id)
              .update({ coverPhoto: downloadURL })
              .then(() => {
                data.coverPhoto = downloadURL
                onSuccess(data)
                updateEventInterestStatus(
                  doc.id,
                  true,
                  false,
                  () => {},
                  () => {}
                )
              })
          })
          .catch((err) => {
            onSuccess(data)
            console.log("err", err)
          })
      })
    })
    .catch((err) => {
      console.log("err", err)
      window.alert("Error", "An error has occured please try later.", [
        { text: "Ok", onPress: onSuccess },
      ])
      onSuccess()
    })
}

export const updateEvent = (
  eventId,
  data,
  coverPhotos,
  onSuccess
) => {

  // console.log("update event", eventId, data)
  firestore
    .collection("events")
    .doc(eventId)
    .update(data)
    .then(() => {
      coverPhotos.map((val, index) => {
        let ref = storage.ref(`eventCoverPhotos/${eventId}/${index}.jpg`)
        ref
          .put(val.blob, { cacheControl: "max-age=31536000" })
          .then(async (result) => {
            // console.log('updateEvent uploaded cover photo!', result)
            const downloadURL = await ref.getDownloadURL()
            firestore
              .collection("events")
              .doc(eventId)
              .update({ coverPhoto: downloadURL })
              .then(() => {
                data.coverPhoto = downloadURL
                  onSuccess(data)
                  // Alert.alert("Success", "Update Successful", [
                  //   { text: "Ok", onPress: () => onSuccess(data) },
                  // ])
              })
          })
          .catch((err) => {
            console.log("err", err)
          })
      })
      if (coverPhotos?.length===0) {
        onSuccess(data)
      }
    })
    .catch((err) => {
      console.log("err", err)
      // Alert.alert("Error", "An error has occured please try later.", [
      //   { text: "Ok", onPress: () => onSuccess(data) },
      // ])
    })
}

export const updateEventInterestStatus = async (
  eventId,
  interested,
  going,
  setInterested,
  setGoing
) => {
  const uid = auth.currentUser.uid

  const data = {
    eventId,
    lastUpdated: new Date(),
    userId: uid,
  }
  if (interested !== null) data.interested = !interested
  if (going !== null) data.going = !going

  if (data.going) data.interested = false

  firestore
    .collection("event_interest")
    .doc(`${eventId}-${uid}`)
    .set(data, { merge: true })
    .then(() => {
      if (interested !== null) setInterested(!interested)
      if (going !== null) setGoing(!going)
    })
}

export const deleteEvent = (event,onSuccess) => {
  firestore
    .collection("events")
    .doc(event?.id)
    .delete()
    .then(() => {
      console.log('here', event.id)
      onSuccess()
    })
    .catch(e => {
      window.alert('There was an error deleting your event. Please try again')
    })
}

export const getEventAttendees = (eventId, setAttendees) => {
  return firestore
    .collection("event_interest")
    .where("eventId", "==", eventId)
    .onSnapshot((querySnapshot) => {
      const temp = []
      querySnapshot.forEach((doc) => {
        const attendee = {
          id: doc.id,
          ...doc.data(),
        }
        temp.push(attendee)
      })
      let promises = temp.map((x) => getUsersFromMembersPromise(x.userId, x))
      Promise.all(promises)
        .then((users) => {
          setAttendees(users.sort((a,b) => getUsersName(a).toLowerCase() > getUsersName(b).toLowerCase() ? 1 : -1))
        })
        .catch((e) => {
          console.log("getEventAttendees error getting user promise", e)
        })
    }, e => {
      console.log("getEventAttendees listening for attendees", e)
    })
}
