import React, { useEffect, useState } from "react"
import {
  StyleSheet,
  View,
  ScrollView,
  TouchableOpacity,
  Image,
} from "react-native"
import { COLORS } from "constants/Colors"
import { PrimaryText } from "components/Common/PrimaryText"
import { useEvents } from "context/EventContext"
import { NavHeader } from "components/Headers/NavHeader"
import { useNavigate } from "react-router-dom"
import { getEventDate } from "utils/events-helper"
import { getUsersName } from "utils/helpers"
import { getEventAttendees, updateEvent } from "services/events-service"
import { ActivityIndicator } from "react-native-web"
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { EventAttendeeCard } from "components/Cards/EventAttendeeCard"
import { useRayze } from "context/RayzeContext"

const geofire = require("geofire-common")

const tabs = ["Going", "Interested"]

export function EventDetail() {
  const navigate = useNavigate()
  const { members, userType, selectedOrg } = useRayze()
  const { selectedEvent, setSelectedEvent, causes } = useEvents()

  const [activeTab, setActiveTab] = useState(0)

  const [showEditEventModal, setShowEditEventModal] = useState(false)
  const [details, setDetails] = useState({ ...selectedEvent })
  const [selectedCauses, setSelectedCauses] = useState([])
  const [newEventCoverPhotos, setNewEventCoverPhotos] = useState([
    selectedEvent?.coverPhoto,
  ])
  const [disabled, setDisabled] = useState(true)

  const [going, setGoing] = useState([])
  const [profilesFiltered, setProfilesFiltered] = useState([])
  const [interested, setInterested] = useState([])

  const [loading, setLoading] = useState(false)

  const statistics = [
    {
      title: "Going",
      amount: selectedEvent?.going || 0,
    },
    {
      title: "Interested",
      amount: selectedEvent?.interested || 0,
    },
  ]

  const eventDetails = [
    {
      title: "Location",
      value:
        selectedEvent?.mode === "In-Person"
          ? `${selectedEvent?.address?.line1}\n${selectedEvent?.address?.city}, ${selectedEvent?.address?.state}, ${selectedEvent?.address?.zipCode} `
          : "Virtual Event",
      capitalize: true,
      // value: "1234 Fake Street Lumberton, SC 28358",
    },
    {
      title: "Date",
      value: getEventDate(selectedEvent),
    },
    {
      title: "Creator",
      value: getUsersName(members.find((x) => x.id === selectedEvent?.creator)),
    },
    {
      title: "About",
      value: selectedEvent?.about,
    },
  ]

  useEffect(() => {
    if (!selectedEvent) return
    setDetails({ ...selectedEvent })

    const sub = getEventAttendees(selectedEvent.id, gotProfiles)
    return () => {
      sub()
    }
  }, [selectedEvent])

  function gotProfiles(profiles) {
    const going = profiles.filter((x) => x.going)
    setGoing(going)
    const interested = profiles.filter((x) => x.interested && !x.going)
    console.log("test", interested)
    setInterested(interested)
  }

  useEffect(() => {
    ;(details.mode &&
      (details.mode === "In-Person"
        ? details.address.line1 &&
          details.address.city &&
          details.address.state &&
          details.address.zipCode &&
          details.latLng
        : details.meetingInfo) &&
      details.name !== "" &&
      details.about !== "" &&
      details.endTime > details.startTime &&
      selectedCauses?.length > 0 &&
      newEventCoverPhotos.length > 0 &&
      !shallowEqual(selectedCauses, selectedEvent?.causes)) ||
    !shallowEqual(details, selectedEvent) ||
    !shallowEqual(newEventCoverPhotos, [selectedEvent?.coverPhoto])
      ? setDisabled(false)
      : setDisabled(true)
  }, [details, newEventCoverPhotos, selectedCauses])

  const shallowEqual = (objA, objB) => {
    if (!objA || !objB) {
      return objA === objB
    }
    return !Boolean(
      Object.keys(Object.assign({}, objA, objB)).find(
        (key) => objA[key] !== objB[key]
      )
    )
  }

  function onBack() {
    setSelectedEvent(null)
    navigate(-1)
  }

  const saveEvent = () => {
    setLoading(true)

    details.causes = selectedCauses
    details.lowercaseName = details.name
      .toLowerCase()
      .replace(/[^a-zA-Z ]/g, "")
    details.skills = []
    const { lat, lng } = details.latLng
    const hash = geofire.geohashForLocation([lat, lng])
    details.hash = hash
    details.orgType = selectedOrg.type
    details.orgName = selectedOrg.name
    details.address.city = details.address.city.toLowerCase()
    details.address.state = details.address.state.toLowerCase()

    let newCoverPhoto = []
    if (selectedEvent?.coverPhoto !== newEventCoverPhotos[0]) {
      //update cover photo
      newCoverPhoto = newEventCoverPhotos
    }

    updateEvent(selectedEvent?.id, details, newCoverPhoto, onSuccess)
  }

  function onSuccess(event) {
    setLoading(false)
    setShowEditEventModal(false)
    setSelectedEvent({ ...event })
  }

  return (
    <View style={styles.container}>
      <NavHeader
        title={selectedEvent?.name}
        onBack={onBack}
        onEdit={
          userType === "Leader"
            ? () => setShowEditEventModal(!showEditEventModal)
            : null
        }
      />

      <View style={[styles.info, { marginTop: 24 }]}>
        <View style={styles.statsPhoto}>
          <View style={styles.cover_img_v}>
            <Image
              style={{ width: "100%", height: "100%", borderRadius: 12 }}
              source={{ uri: selectedEvent?.coverPhoto }}
              resizeMode={"contain"}
            />
          </View>
        </View>
        <View style={[styles.stats, { marginLeft: 16 }]}>
          {statistics.map((s, i) => {
            const { title, amount } = s
            return (
              <View style={styles.statContainer} key={i}>
                <PrimaryText
                  fontSize={18}
                  fontWeight={400}
                  style={styles.header}
                >
                  {title}
                </PrimaryText>
                <PrimaryText
                  fontSize={24}
                  fontWeight={700}
                  style={styles.header}
                >
                  {amount}
                </PrimaryText>
              </View>
            )
          })}
        </View>
      </View>

      <View style={styles.info}>
        <View style={styles.infoContainer}>
          {eventDetails.map((u, i) => {
            const { title, value } = u
            return (
              <View style={styles.infoTextRow} key={i}>
                <View style={{ marginRight: 12 }} className="text-right mr-4">
                  <PrimaryText
                    fontSize={14}
                    fontWeight={400}
                    style={{ width: 180, textAlign: "right" }}
                  >
                    {title}
                  </PrimaryText>
                </View>
                <PrimaryText
                  fontSize={16}
                  fontWeight={600}
                  style={u?.capitalize && { textTransform: "capitalize" }}
                >
                  {value}
                </PrimaryText>
              </View>
            )
          })}
        </View>

        <ScrollView style={styles.causesContainer}>
          <PrimaryText fontSize={16} fontWeight={600}>
            {"Causes"}
          </PrimaryText>
          <View style={styles.causes}>
            {causes.map((item) => (
              <View style={styles.cause} key={item.id}>
                <PrimaryText
                  fontSize={14}
                  fontWeight={400}
                  style={{ color: COLORS.primary.lightBlue }}
                >
                  {item.name}
                </PrimaryText>
                {/* <FaIcons.FaTimes /> */}
              </View>
            ))}
          </View>
        </ScrollView>
      </View>

      <View style={styles.tabs}>
        {tabs.map((t, i) => {
          return (
            <TouchableOpacity
              key={i}
              style={[styles.tabsButton, i === activeTab && styles.tabActive]}
              onPress={() => setActiveTab(i)}
            >
              <PrimaryText
                fontSize={14}
                fontWeight={700}
                style={[
                  styles.tabText,
                  i === activeTab && styles.tabTextActive,
                ]}
              >
                {t}
              </PrimaryText>
            </TouchableOpacity>
          )
        })}
      </View>

      <PrimaryText
        fontSize={14}
        fontWeight={400}
        style={{
          color: COLORS.primary.darkGray,
          marginTop: 12,
          marginBottom: -12,
        }}
      >
        {activeTab === 0
          ? `RSVP (${selectedEvent?.going || 0})`
          : `Interested (${selectedEvent?.interested || 0})`}
      </PrimaryText>

      <Table className="table table-borderless">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Username</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {activeTab === 0 &&
            going &&
            going.map((item, i) => {
              return <EventAttendeeCard user={item} key={i} />
            })}

          {activeTab === 1 &&
            interested &&
            interested.map((item, i) => {
              return <EventAttendeeCard user={item} key={i} />
            })}
        </TableBody>
      </Table>


      {loading && (
        <ActivityIndicator
          animating={loading}
          size={"large"}
          style={styles.activity}
          color={COLORS.primary.lightBlue}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  activity: {
    width: "100%",
    height: "100%",
    backgroundColor: COLORS.primary.white,
    position: "absolute",
    top: 0,
    bottom: 0,
    zIndex: 999,
  },
  container: {
    flex: 1,
    width: "100%",
    paddingTop: 20,
    backgroundColor: COLORS.primary.white,
  },
  header: {
    color: COLORS.primary.darkBlue,
  },
  cover_img_v: {
    width: "100%",
    aspectRatio: 16 / 9,
  },
  statsPhoto: {
    width: "30%",
  },
  stats: {
    flex: 1,
    flexDirection: "row",
    padding: 27,
    justifyContent: "space-around",
    backgroundColor: COLORS.primary.offWhite,
    borderRadius: 12,
  },
  statContainer: {
    alignItems: "center",
  },
  description: {
    marginVertical: 24,
    padding: 27,
    backgroundColor: COLORS.primary.offWhite,
    borderRadius: 12,
    marginBottom: 16,
  },
  info: {
    flexDirection: "row",
    marginBottom: 16,
    maxHeight: 300,
  },
  infoContainer: {
    flex: 1,
    padding: 20,
    justifyContent: "space-around",
    backgroundColor: COLORS.primary.offWhite,
    borderRadius: 12,
  },
  infoTextRow: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 6,
  },
  causesContainer: {
    flex: 1,
    // height: 200,
    padding: 20,
    backgroundColor: COLORS.primary.offWhite,
    borderRadius: 12,
    marginLeft: 16,
  },
  causes: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    paddingBottom: 20,
    paddingTop: 12,
  },
  cause: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 4,
    paddingHorizontal: 8,
    marginRight: 8,
    marginBottom: 10,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: COLORS.primary.lightBlue,
  },
  tabs: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
  tabsButton: {
    width: 168,
    height: 36,
    backgroundColor: COLORS.primary.gray,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    marginRight: 12,
    alignItems: "center",
    justifyContent: "center",
  },
  tabActive: {
    backgroundColor: COLORS.primary.offWhite,
    color: "black",
  },
  tabText: {
    textAlign: "center",
    color: COLORS.primary.white,
    fontWeight: "500",
  },
  tabTextActive: {
    color: COLORS.primary.darkBlue,
    fontWeight: "700",
  },
  button: {
    flexDirection: "row",
    backgroundColor: COLORS.primary.lightBlue,
    width: 200,
    justifyContent: "center",
    alignItems: "center",
    padding: 8,
    margin: 12,
    borderRadius: 6,
  },
})
